import React, { useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const CompanyPage = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const drawGrid = () => {
      const gridSize = 80;
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(30, 30, 30, 0.8)';
      ctx.lineWidth = 1;

      for (let x = 0; x <= canvas.width; x += gridSize) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
      }

      for (let y = 0; y <= canvas.height; y += gridSize) {
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
      }

      ctx.stroke();
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawGrid();
      animationFrameId = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="min-h-screen bg-[#090909] text-white">
      <div className="relative min-h-screen overflow-hidden">
        <canvas ref={canvasRef} className="fixed inset-0 pointer-events-none" style={{ opacity: 0.5 }} />
        
        {/* Gradient overlays */}
        <div className="fixed inset-0">
          <div 
            className="absolute w-[600px] h-[600px]"
            style={{
              background: 'radial-gradient(circle at center, rgba(255, 140, 50, 0.3) 0%, rgba(255, 140, 50, 0) 70%)',
              bottom: '-200px',
              left: '-100px',
              filter: 'blur(80px)',
            }}
          />
          <div 
            className="absolute w-[600px] h-[600px]"
            style={{
              background: 'radial-gradient(circle at center, rgba(123, 97, 255, 0.3) 0%, rgba(123, 97, 255, 0) 70%)',
              top: '-200px',
              right: '-100px',
              filter: 'blur(80px)',
            }}
          />
        </div>

        {/* Content */}
        <div className="relative z-10">
          {/* Header */}
          <header className="p-6 flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img 
                src="/assets/lawdie-logo.png" 
                alt="Lawdie Logo" 
                className="w-8 h-8"
                onError={(e) => {
                  e.target.style.display = 'none';
                  e.target.nextElementSibling.style.display = 'block';
                }}
              />
              <svg className="w-8 h-8 hidden" viewBox="0 0 32 32">
                <path fill="#FF9500" d="M16 0L32 8V24L16 32L0 24V8L16 0Z"/>
                <path fill="#FF4500" d="M16 4L28 10V22L16 28L4 22V10L16 4Z"/>
              </svg>
              <span className="text-base font-medium">Lawdie</span>
            </Link>

            <nav>
              <ul className="flex items-center space-x-8">
                <li>
                  <Link to="/company" className="text-sm hover:text-gray-300">Company</Link>
                </li>
                <li>
                  <Link to="" className="text-sm hover:text-gray-300">Services</Link>
                </li>
                <li>
                  <Link to="/coming-soon" className="text-sm hover:text-gray-300">
                    Lawyer Search <span className="text-[#7B61FF]">(BETA)</span>
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/signup"
                    className="text-sm px-6 py-2 rounded-full border border-[#FF9500] text-[#FF9500] hover:bg-[#FF9500] hover:text-black transition-all duration-300"
                  >
                    Sign up!
                  </Link>
                </li>
              </ul>
            </nav>
          </header>

          {/* Main Content */}
          <main className="container mx-auto px-6 py-16">
            <div className="max-w-2xl">
              <h2 className="text-[#FF9500] text-sm font-medium mb-4">About us</h2>
              
              <h1 className="text-5xl font-bold mb-6">Lawdie</h1>
              
              <p className="text-gray-400 mb-8 text-base leading-relaxed">
                Connects clients with lawyers based on budget, location, and case details. 
                It streamlines the legal process with personalized matching, encrypted data, 
                and tools for efficient case and referral management.
              </p>

              <Link 
                to="/contact"
                className="inline-block px-6 py-2 rounded-full bg-[#FF9500] text-black text-sm font-medium hover:bg-[#FF8500] transition-all duration-300"
              >
                CONTACT
              </Link>
            </div>

            <div className="mt-24">
              <h2 className="text-2xl font-bold mb-6">Company</h2>
              <div className="max-w-3xl text-gray-400 space-y-6">
                <p className="text-base leading-relaxed">
                  At Lawdie, we are dedicated to solving the challenges both clients and attorneys face 
                  in today's legal landscape. Our platform is designed as a one-stop shop, helping clients 
                  find legal representation that matches their specific needs, budget, and case details with ease. 
                  We remove the guesswork by offering tailored, personalized lawyer matches based on various criteria.
                </p>
                <p className="text-base leading-relaxed">
                  For attorneys, our platform simplifies the referral process, increases their online presence, 
                  and provides seamless case management tools, allowing them to focus on what matters most—providing 
                  quality legal services. Whether you're looking for legal help or aiming to streamline your practice, 
                  Lawdie bridges the gap between clients and attorneys with a user-friendly, efficient solution.
                </p>
              </div>
            </div>

            <div className="mt-24">
              <h2 className="text-2xl font-bold mb-6">Team</h2>
              <div className="max-w-3xl text-gray-400">
                <p className="text-base leading-relaxed">
                  Sanay Shajith and Meeth Naik, both second-year students at Georgia Tech, are the driving force 
                  behind Lawdie. Sanay is passionate about community service, hiking, and golfing, bringing a 
                  well-rounded perspective and dedication to making a positive impact. Meeth, with his love for 
                  coding, running, and reading, adds a sharp technical edge to the team. Together, they are committed 
                  to using their diverse skills and interests to solve complex problems and make legal services more 
                  accessible and efficient.
                </p>
              </div>
            </div>

            <div className="mt-12">
              <Link 
                to="/"
                className="inline-block px-6 py-2 text-sm rounded-full border border-[#FF9500] text-[#FF9500] hover:bg-[#FF9500] hover:text-black transition-all duration-300"
              >
                GO TO HOME
              </Link>
            </div>
          </main>

          {/* Footer */}
          <footer className="bg-black py-16 border-t border-gray-800">
            <div className="max-w-6xl mx-auto px-6">
              <div className="grid grid-cols-1 md:grid-cols-5 gap-12">
                <div>
                  <h3 className="font-bold mb-4">Company</h3>
                  <ul className="space-y-2">
                    <li><button className="text-gray-400 hover:text-white">About us</button></li>
                    <li><button className="text-gray-400 hover:text-white">Team</button></li>
                    <li><button className="text-gray-400 hover:text-white">Careers</button></li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-bold mb-4">Services</h3>
                  <ul className="space-y-2">
                    <li><button className="text-gray-400 hover:text-white">Branding</button></li>
                    <li><button className="text-gray-400 hover:text-white">Web development</button></li>
                    <li><button className="text-gray-400 hover:text-white">Digital marketing</button></li>
                    <li><button className="text-gray-400 hover:text-white">Mobile app</button></li>
                    <li><button className="text-gray-400 hover:text-white">SEO</button></li>
                    <li><button className="text-gray-400 hover:text-white">User testing</button></li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-bold mb-4">Resources</h3>
                  <ul className="space-y-2">
                    <li><button className="text-gray-400 hover:text-white">Blog</button></li>
                    <li><button className="text-gray-400 hover:text-white">Case study</button></li>
                    <li><button className="text-gray-400 hover:text-white">Testimonials</button></li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-bold mb-4">Follow us</h3>
                  <ul className="space-y-2">
                    <li><button className="text-gray-400 hover:text-white">LinkedIn</button></li>
                  </ul>
                </div>

                <div>
                  <div className="flex items-center mb-4">
                    <div className="w-8 h-8 bg-gradient-to-br from-orange-400 to-orange-600 rounded-lg mr-2" />
                    <span className="font-bold">Lawdie</span>
                  </div>
                  <p className="text-sm text-gray-400 mb-4">Get latest updates</p>
                  <input
                    type="email"
                    placeholder="Your email"
                    className="w-full px-4 py-2 rounded bg-[#111111] border border-gray-800 focus:outline-none focus:border-[#7B61FF]"
                  />
                </div>
              </div>

              <div className="mt-16 text-center text-gray-400 text-sm">
                Created by Lawdie Inc.
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;