// components/signup/registration/PhaseIndicator.js
import React from 'react';

export const PhaseIndicator = ({ currentPhase }) => (
  <div className="flex justify-center space-x-2 mb-8">
    {[1, 2, 3].map((phase) => (
      <div
        key={phase}
        className={`h-2 w-16 ${
          phase <= currentPhase ? 'bg-[#1A1A1A]' : 'bg-[#A39B8F]'
        }`}
      />
    ))}
  </div>
);