// components/Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, Search } from 'lucide-react';
import { fetchUserReferrals } from './backend/referralService';
import { StatCard } from './components/dashboard(home)/StatCard';
import { FilterPill } from './components/dashboard(home)/FilterPill';
import { CustomerTable } from './components/dashboard(home)/CustomerTable';
import { CustomerListItem } from './components/dashboard(home)/CustomerListItem';
import { PlaceholderView } from './components/dashboard(home)/PlaceholderView';

const Home = () => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('overview');
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({
    totalReferrals: 0,
    totalReferees: 0,
    activeReferees: 0
  });

  useEffect(() => {
    loadReferrals();
  }, []);

  const loadReferrals = async () => {
    try {
      setLoading(true);
      const result = await fetchUserReferrals();
      
      if (!result.success) {
        throw new Error(result.error);
      }

      setCustomers(result.data);
      
      // Update metrics based on the fetched data
      setMetrics({
        totalReferrals: result.data.length,
        totalReferees: new Set(result.data.map(r => r.contact)).size,
        activeReferees: result.data.filter(r => 
          ['pending', 'received', 'viewed', 'renegotiating', 'accepted', 'litigation', 'billing']
          .includes(r.status.toLowerCase())
        ).length
      });

    } catch (error) {
      console.error('Error loading referrals:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderView = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="text-[#3d3731]">Loading referrals...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="text-red-500">Error: {error}</div>
        </div>
      );
    }

    if (customers.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="text-[#3d3731] mb-4">No referrals found</div>
          <button
            onClick={() => navigate('/submit-referral')}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731]"
          >
            Create Your First Referral
          </button>
        </div>
      );
    }

    switch (currentView) {
      case 'overview':
        return <CustomerTable customers={customers} onRefresh={loadReferrals} />;
      case 'list':
        return (
          <div className="select-none outline-none focus:outline-none" tabIndex="-1">
            {customers.map((customer, index) => (
              <CustomerListItem key={customer.id || index} {...customer} />
            ))}
          </div>
        );
      case 'table':
      case 'segment':
      case 'custom':
        return <PlaceholderView type={currentView.charAt(0).toUpperCase() + currentView.slice(1)} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 overflow-auto bg-[#f5f1eb]">
      <div className="py-8 px-10">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold text-[#3d3731]">Customers</h1>
          <div className="flex space-x-3">
            <button className="px-4 py-2 border border-[#a39b8f] rounded-md text-[#3d3731] bg-[#d8d1c7] hover:bg-[#c5beb2]">
              <span className="mr-2">↓</span> Import
            </button>
            <button 
              onClick={() => navigate('/submit-referral')}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-[#e9e4dc] bg-black hover:bg-[#3d3731]"
            >
              + Add Referral
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-3 gap-6 mb-8">
          <StatCard 
            title="Referrals" 
            value={metrics.totalReferrals.toString()}
            change="+20%" 
          />
          <StatCard 
            title="Referee's" 
            value={metrics.totalReferees.toString()}
            change="+15%" 
          />
          <StatCard 
            title="Active Referee's" 
            value={metrics.activeReferees.toString()}
            avatars={[1,2,3,4,5]} 
          />
        </div>

        <div className="bg-[#f5f1eb] shadow rounded-lg">
          <div className="px-6 py-4 border-b border-[#d8d1c7] flex justify-between items-center">
            <div className="flex space-x-4">
              {['Overview', 'Table', 'List view', 'Segment', 'Custom'].map((view) => (
                <button
                  key={view}
                  onClick={() => setCurrentView(view.toLowerCase().replace(' ', ''))}
                  className={`px-3 py-2 text-sm font-medium ${
                    currentView === view.toLowerCase().replace(' ', '')
                      ? 'border-b-2 border-black text-[#3d3731]'
                      : 'text-[#a39b8f] hover:text-[#3d3731]'
                  }`}
                >
                  {view}
                </button>
              ))}
            </div>
          </div>

          <div className="px-6 py-4 flex justify-between items-center">
            <div className="flex space-x-2">
              <FilterPill>All time</FilterPill>
              <FilterPill>US, AU, +4</FilterPill>
              <button className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#f5f1eb] border border-[#a39b8f] text-[#3d3731] hover:bg-[#d8d1c7]">
                More filters
                <ChevronDown size={16} className="ml-1" />
              </button>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="focus:ring-[#a39b8f] focus:border-[#a39b8f] block w-full pl-10 pr-3 py-2 border border-[#a39b8f] rounded-md leading-5 bg-[#f5f1eb] placeholder-[#a39b8f] focus:outline-none focus:placeholder-[#c5beb2] sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search size={20} className="text-[#a39b8f]" />
              </div>
            </div>
          </div>

          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default Home;