// components/dashboard_home_/FilterPill.js
import React from 'react';

export const FilterPill = ({ children }) => (
  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-[#d8d1c7] text-[#3d3731]">
    {children}
    <button type="button" className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-[#3d3731] hover:bg-[#c5beb2] focus:outline-none focus:bg-[#a39b8f] focus:text-[#e9e4dc]">
      <span className="sr-only">Remove filter</span>
      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
);

export default FilterPill;