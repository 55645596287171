import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../../backend/supabase';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleAuthRedirect = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) throw error;
        
        if (session) {
          const role = localStorage.getItem('userRole');
          
          // First check if user exists in the users table
          const { data: existingUser, error: userError } = await supabase
            .from('users')
            .select('*')
            .eq('email', session.user.email)
            .single();

          if (userError && userError.code !== 'PGRST116') {
            throw userError;
          }

          // If user doesn't exist at all, handle based on role
          if (!existingUser) {
            if (role === 'client') {
              // For new clients, create user record and redirect to coming soon
              const { error: createError } = await supabase
                .from('users')
                .insert([{
                  first_name: session.user.user_metadata?.given_name || '',
                  last_name: session.user.user_metadata?.family_name || '',
                  email: session.user.email,
                  user_type: 'client',
                  is_active: true,
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString()
                }]);

              if (createError) throw createError;
              localStorage.removeItem('tempAuthUser');
              navigate('/coming-soon');
              return;
            } else if (role === 'attorney') {
              // New attorney needs to complete registration
              navigate('/signup/register');
              return;
            }
          }

          // User exists - handle based on role
          if (role === 'client') {
            navigate('/coming-soon');
            return;
          }

          if (role === 'attorney') {
            // Check if they have a lawyer profile
            const { data: lawyerProfile, error: lawyerError } = await supabase
              .from('lawyer_profiles')
              .select('*')
              .eq('user_id', existingUser.id)
              .single();

            if (lawyerError && lawyerError.code !== 'PGRST116') {
              throw lawyerError;
            }

            if (!lawyerProfile) {
              // Attorney exists but needs to complete their lawyer profile
              navigate('/signup/register');
              return;
            }

            // Attorney with complete profile - go to dashboard
            navigate('/dashboard');
            return;
          }

        } else {
          navigate('/signup/login');
        }
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/signup/login');
      }
    };

    handleAuthRedirect();
  }, [navigate, searchParams]);

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex items-center justify-center">
      <div className="text-center">
        <div className="text-[#1A1A1A] text-xl">Completing authentication...</div>
        <div className="mt-4 animate-spin w-8 h-8 border-4 border-[#1A1A1A] border-t-transparent rounded-full"/>
      </div>
    </div>
  );
};

export default AuthCallback;