// components/signup/registration/PracticeAreaSelector.js
import React from 'react';

const PRACTICE_AREAS = [
  'Personal Injury',
  'Family Law',
  'Criminal Defense',
  'Real Estate',
  'Business Law',
  'Immigration',
  'Estate Planning',
  'Employment Law',
  'Intellectual Property',
  'Tax Law',
  'Civil Litigation',
  'Corporate Law'
];

export const PracticeAreaSelector = ({ selectedAreas, onChange }) => (
  <div>
    <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
      PRACTICE AREAS
    </label>
    <div className="grid grid-cols-2 gap-4">
      {PRACTICE_AREAS.map(area => (
        <label key={area} className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={selectedAreas.includes(area)}
            onChange={() => {
              const newAreas = selectedAreas.includes(area)
                ? selectedAreas.filter(a => a !== area)
                : [...selectedAreas, area];
              onChange(newAreas);
            }}
            className="w-4 h-4 border-[#1A1A1A] rounded-none focus:ring-[#1A1A1A]"
          />
          <span className="text-sm text-[#1A1A1A]">{area}</span>
        </label>
      ))}
    </div>
  </div>
);
