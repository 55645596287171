import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHandlers } from './authhandlers';
import { supabase } from '../../backend/supabase';

import { PhaseIndicator } from './registration/PhaseIndicator';
import { FormHeader } from './registration/FormHeader';
import { SocialSignIn } from './registration/SocialSignIn';
import { FormFooter } from './registration/FormFooter';

import { PersonalDetails } from './registration/RegistrationPhases/PersonalDetails';
import { FirmDetails } from './registration/RegistrationPhases/FirmDetails';
import { BarVerification } from './registration/RegistrationPhases/BarVerification';

const RegistrationForm = () => {
  const navigate = useNavigate();
  
  // Auth & Loading States
  const { googleLogin, handleLinkedInLogin, loading: authLoading } = useAuthHandlers();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  
  // Registration Flow States
  const [currentPhase, setCurrentPhase] = useState(1);
  const [userRole, setUserRole] = useState('');
  const [isOAuthUser, setIsOAuthUser] = useState(false);
  
  // Form States - Aligned with DB Schema
  const [formData, setFormData] = useState({
    // Personal Details
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    passwordErrors: [],
    dateOfBirth: '',
    agreeToTerms: false,
    publicReferrals: false,
    
    // Attorney-specific Details
    firmName: '',
    office_address: '',  // Changed from firmAddress
    firmCity: '',
    firmState: '',
    firmZip: '',
    practiceAreas: [],
    barId: '',
    yearsOfExperience: 0,
    bio: '',
    website: '',
    is_visible: true,
  });

  useEffect(() => {
    console.log('Initializing registration form...');
    const role = localStorage.getItem('userRole');
    if (!role) {
      console.log('No role found, redirecting to signup...');
      navigate('/signup');
      return;
    }
    setUserRole(role);
    console.log('User role set:', role);

    const tempAuthUser = localStorage.getItem('tempAuthUser');
    if (tempAuthUser) {
      try {
        const authUserData = JSON.parse(tempAuthUser);
        console.log('OAuth user data found:', { email: authUserData.email });
        setFormData(prev => ({
          ...prev,
          firstName: authUserData.user_metadata?.given_name || '',
          lastName: authUserData.user_metadata?.family_name || '',
          email: authUserData.email || '',
        }));
        setIsOAuthUser(true);
        
        if (role === 'attorney') {
          console.log('Attorney detected, moving to firm details...');
          setCurrentPhase(2);
        }
      } catch (error) {
        console.error('Error handling auth data:', error);
        localStorage.removeItem('tempAuthUser');
        setError('Error loading user data. Please try again.');
      }
    }
  }, [navigate]);

  const validatePhase = (phase) => {
    console.log(`Validating phase ${phase}`);
    const errors = {};

    const validateField = (field, value, message) => {
      if (!value?.toString().trim()) {
        errors[field] = message;
      }
    };

    // Validate email format if present
    if (formData.email && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }

    // Validate phone format if present
    if (formData.phone && !/^\+?[1-9][0-9]{7,14}$/.test(formData.phone.replace(/\D/g, ''))) {
      errors.phone = 'Invalid phone format';
    }

    // Validate ZIP format if present
    if (formData.firmZip && !/^\d{5}(-\d{4})?$/.test(formData.firmZip.replace(/[^\d-]/g, ''))) {
      errors.firmZip = 'ZIP code must be 5 digits or 5-4 digits';
    }

    switch (phase) {
      case 1: // Personal Details
        validateField('firstName', formData.firstName, 'First name is required');
        validateField('lastName', formData.lastName, 'Last name is required');
        validateField('email', formData.email, 'Email is required');
        
        if (!isOAuthUser) {
          validateField('phone', formData.phone, 'Phone is required');
          validateField('password', formData.password, 'Password is required');
          validateField('dateOfBirth', formData.dateOfBirth, 'Date of birth is required');
          
          if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
          }
        }
        
        if (!formData.agreeToTerms) {
          errors.agreeToTerms = 'You must agree to the terms';
        }
        break;

      case 2: // Firm Details
        validateField('firmName', formData.firmName, 'Firm name is required');
        validateField('office_address', formData.office_address, 'Office address is required');
        validateField('firmCity', formData.firmCity, 'City is required');
        validateField('firmState', formData.firmState, 'State is required');
        validateField('firmZip', formData.firmZip, 'ZIP code is required');
        
        if (isOAuthUser) {
          validateField('phone', formData.phone, 'Phone number is required');
        }
        
        if (!formData.practiceAreas?.length) {
          errors.practiceAreas = 'At least one practice area is required';
        }
        break;

      case 3: // Bar Verification
        validateField('barId', formData.barId, 'Bar ID is required');
        break;
    }

    console.log('Validation errors:', errors);
    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`Form field changed: ${name}`, { type, value: type === 'checkbox' ? checked : value });
    
    let finalValue = type === 'checkbox' ? checked : value;

    // Format phone number if it's the phone field
    if (name === 'phone') {
      finalValue = value.replace(/\D/g, '').slice(0, 10);
    }

    // Format ZIP code if it's the ZIP field
    if (name === 'firmZip') {
      finalValue = value.replace(/[^\d-]/g, '');
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: finalValue
    }));

    // Clear validation error when field is modified
    if (validationErrors[name]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const createUserRecord = async () => {
    try {
      console.log('Starting registration process...');
      let authUser;

      if (isOAuthUser) {
        console.log('Processing OAuth user...');
        const { data: { user }, error: getUserError } = await supabase.auth.getUser();
        if (getUserError) throw getUserError;
        if (!user) throw new Error('No authenticated user found');
        authUser = user;
      } else {
        console.log('Processing email signup...');
        const { data, error: signUpError } = await supabase.auth.signUp({
          email: formData.email,
          password: formData.password,
          options: {
            data: {
              first_name: formData.firstName,
              last_name: formData.lastName,
              user_type: 'attorney'
            }
          }
        });

        if (signUpError) throw signUpError;
        if (!data?.user) throw new Error('No user data returned from signup');
        authUser = data.user;
      }

      // Format the zip code to ensure it matches the constraint
      const formattedZipCode = formData.firmZip.replace(/[^\d-]/g, '');
      if (!/^\d{5}(-\d{4})?$/.test(formattedZipCode)) {
        throw new Error('Invalid ZIP code format. Must be 5 digits or 5-4 digits.');
      }

      console.log('Creating public user record...');
      const publicUserData = {
        id: authUser.id,
        first_name: formData.firstName,
        last_name: formData.lastName,
        middle_name: formData.middleName || null,
        email: formData.email.toLowerCase(),
        phone: formData.phone.replace(/\D/g, ''),
        street_address: formData.office_address,
        city: formData.firmCity,
        state: formData.firmState,
        zip_code: formattedZipCode,
        user_type: 'attorney',
        is_public: Boolean(formData.publicReferrals),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      console.log('Public user data:', publicUserData);

      const { data: userData, error: userError } = await supabase
        .from('users')
        .insert([publicUserData])
        .select()
        .single();

      if (userError) {
        console.error('Error creating public user:', userError);
        if (!isOAuthUser) {
          await supabase.auth.signOut();
        }
        throw userError;
      }

      console.log('Creating lawyer profile...');
      const lawyerProfileData = {
        user_id: userData.id,
        firm_name: formData.firmName,
        practice_areas: formData.practiceAreas,
        bar_number: formData.barId,
        years_of_experience: 0,
        office_address: `${formData.office_address}, ${formData.firmCity}, ${formData.firmState} ${formattedZipCode}`,
        referral_count: 0,
        is_visible: true,
        verification_status: 'pending',
        bio: formData.bio || null,
        website: formData.website || null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      console.log('Lawyer profile data:', lawyerProfileData);

      const { error: lawyerError } = await supabase
        .from('lawyer_profiles')
        .insert([lawyerProfileData]);

      if (lawyerError) {
        console.error('Error creating lawyer profile:', lawyerError);
        await supabase
          .from('users')
          .delete()
          .match({ id: userData.id });
          
        if (!isOAuthUser) {
          await supabase.auth.signOut();
        }
        throw lawyerError;
      }

      console.log('Registration completed successfully');
      localStorage.removeItem('tempAuthUser');
      navigate('/dashboard');

    } catch (error) {
      console.error('Registration failed:', error);
      throw new Error(`Registration failed: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setValidationErrors({});
    setLoading(true);

    try {
      console.log(`Submitting form for phase ${currentPhase}`);
      const errors = validatePhase(currentPhase);
      if (Object.keys(errors).length > 0) {
        console.log('Validation failed:', errors);
        setValidationErrors(errors);
        setLoading(false);
        return;
      }

      if (userRole === 'attorney' && currentPhase < 3) {
        console.log('Moving to next phase');
        setCurrentPhase(prev => prev + 1);
        setLoading(false);
        return;
      }

      await createUserRecord();
      
    } catch (error) {
      console.error('Submission error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex flex-col items-center px-4 py-8">
      <div className="fixed top-0 left-0 right-0 flex items-center p-4 text-[#1A1A1A] bg-[#C5C0BB]">
        <button 
          onClick={() => currentPhase > 1 ? setCurrentPhase(prev => prev - 1) : navigate(-1)}
          className="text-sm font-light hover:text-[#2A2A2A] flex items-center gap-2 tracking-wider"
        >
          ← BACK
        </button>
      </div>

      <FormHeader 
        title="CREATE ACCOUNT"
        subtitle={userRole === 'client' ? 'Client Registration' : 
          currentPhase === 1 ? 'Personal Details' :
          currentPhase === 2 ? 'Firm Information' :
          'Verify Bar Credentials'
        }
      />

      {userRole === 'attorney' && <PhaseIndicator currentPhase={currentPhase} />}

      {error && (
        <div className="w-full max-w-md mb-4 p-4 bg-red-100 border border-red-400 text-red-700">
          {error}
        </div>
      )}

      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="space-y-6">
          {currentPhase === 1 ? (
            <PersonalDetails
              formData={formData}
              onChange={handleChange}
              validationErrors={validationErrors}
              isOAuthUser={isOAuthUser}
            />
          ) : currentPhase === 2 ? (
            <FirmDetails
              formData={formData}
              onChange={handleChange}
              validationErrors={validationErrors}
              isOAuthUser={isOAuthUser}
            />
          ) : (
            <BarVerification
              formData={formData}
              onChange={handleChange}
              validationErrors={validationErrors}
            />
          )}

          <div className="space-y-4 mt-8">
            {(currentPhase === 1 || userRole === 'client') && (
              <SocialSignIn
                onGoogleClick={googleLogin}
                onLinkedInClick={handleLinkedInLogin}
                isAttorney={userRole === 'attorney'}
                loading={loading || authLoading}
              />
            )}

            <button
              type="submit"
              disabled={loading || authLoading}
              className="w-full px-6 py-3 border-2 border-[#1A1A1A] text-[#1A1A1A] tracking-wider hover:bg-[#1A1A1A] hover:text-[#C5C0BB] transition-colors disabled:opacity-50"
            >
              {loading 
                ? 'PROCESSING...' 
                : userRole === 'attorney' 
                  ? (currentPhase < 3 ? 'CONTINUE' : 'CREATE ACCOUNT') 
                  : 'CREATE ACCOUNT'
              }
            </button>
          </div>
        </form>

        {/* Footer - only show on first phase or for clients */}
        {(currentPhase === 1 || userRole === 'client') && (
          <FormFooter onLoginClick={() => navigate('/signup/login')} />
        )}
      </div>

      {/* Debug Information in Development */}
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-4 right-4 p-4 bg-black bg-opacity-75 text-white rounded text-xs">
          <pre>
            {JSON.stringify(
              {
                phase: currentPhase,
                role: userRole,
                isOAuth: isOAuthUser,
                errors: validationErrors
              },
              null,
              2
            )}
          </pre>
        </div>
      )}
    </div>
  );
};

export default RegistrationForm;