import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from './backend/supabase';

const LinkedInCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session) {
        navigate('/dashboard');
      } else if (event === 'SIGNED_OUT') {
        navigate('/login');
      }
    });
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex items-center justify-center">
      <div className="text-center">
        <div className="text-[#1A1A1A] text-xl">Completing login...</div>
        <div className="mt-4 animate-spin w-8 h-8 border-4 border-[#1A1A1A] border-t-transparent rounded-full"/>
      </div>
    </div>
  );
};


export default LinkedInCallback;