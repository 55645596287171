// components/dashboard_home_/StatCard.js
import React from 'react';
import { MoreHorizontal } from 'lucide-react';

export const StatCard = ({ title, value, change, avatars }) => (
  <div className="bg-[#e9e4dc] p-6 rounded-lg shadow">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-sm text-[#3d3731]">{title}</h3>
      <MoreHorizontal size={20} className="text-[#a39b8f]" />
    </div>
    <div className="flex items-baseline">
      <span className="text-3xl font-bold mr-2 text-[#3d3731]">{value}</span>
      {change && <span className="text-sm text-green-500">{change}</span>}
    </div>
    {avatars && (
      <div className="mt-4 flex -space-x-2">
        {avatars.map((_, index) => (
          <img key={index} className="inline-block h-6 w-6 rounded-full ring-2 ring-[#e9e4dc]" src={`/api/placeholder/24/24`} alt="" />
        ))}
        <span className="flex items-center justify-center w-6 h-6 rounded-full bg-[#d8d1c7] text-xs font-medium text-[#3d3731]">+4</span>
      </div>
    )}
  </div>
);

export default StatCard;