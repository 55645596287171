// components/signup/registration/FormInput.js
import React from 'react';

export const FormInput = ({ 
  label, 
  name, 
  type = 'text', 
  value, 
  onChange, 
  error, 
  disabled = false,
  required = false,
  className = '',
  ...props 
}) => (
  <div className={className}>
    <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
      {label}
    </label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      className={`w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none focus:ring-1 focus:ring-[#1A1A1A] ${
        error ? 'border-red-500' : ''
      }`}
      {...props}
    />
    {error && (
      <p className="mt-1 text-xs text-red-500">{error}</p>
    )}
  </div>
);
