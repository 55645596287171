import { useState, useCallback } from 'react';
import { s3Service, validateFile } from './uploadService';
import { supabase } from './supabase';

export const useS3Upload = (options = {}) => {
  const {
    maxSizeMB = 10,
    allowedTypes = null,
    folder = 'documents'
  } = options;

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const getCurrentUserEmail = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) throw error;
    if (!user) throw new Error('No authenticated user found');
    return user.email;
  };

  const uploadFile = useCallback(async (file) => {
    try {
      setUploading(true);
      setError(null);
      setProgress(0);

      // Validate file
      validateFile(file, { maxSizeMB, allowedTypes });

      // Get current user's email
      const userEmail = await getCurrentUserEmail();

      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 90) return 90;
          return prev + 10;
        });
      }, 500);

      // Upload file
      const result = await s3Service.uploadFile(file, userEmail, folder);

      clearInterval(progressInterval);
      setProgress(100);

      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setUploading(false);
    }
  }, [maxSizeMB, allowedTypes, folder]);

  const deleteFile = useCallback(async (key) => {
    try {
      await s3Service.deleteFile(key);
      return true;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }, []);

  const listUserFiles = useCallback(async () => {
    try {
      const userEmail = await getCurrentUserEmail();
      return await s3Service.listUserFiles(userEmail, folder);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }, [folder]);

  const resetState = useCallback(() => {
    setUploading(false);
    setError(null);
    setProgress(0);
  }, []);

  return {
    uploadFile,
    deleteFile,
    listUserFiles,
    uploading,
    error,
    progress,
    resetState
  };
};