import React from 'react';
import { MoreVertical } from 'lucide-react';
import StatusBadge from './StatusBadge';
import { useNavigate } from 'react-router-dom';

const LienCard = ({ lien }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dashboard/liens/${lien.id}`);
  };

  return (
    <div 
      className="bg-[#e9e4dc] rounded-lg shadow-sm border border-[#a39b8f] p-6 cursor-pointer hover:shadow-md transition-shadow duration-200"
      onClick={handleClick}
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold text-black">{lien.lienholderName}</h3>
          <p className="text-sm text-[#3d3731]">Case #{lien.caseNumber}</p>
        </div>
        <div className="flex items-center space-x-2">
          <StatusBadge status={lien.status} />
          <button 
            className="text-[#3d3731] hover:text-black transition-colors duration-150"
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click when clicking the menu button
              // Handle menu click
            }}
          >
            <MoreVertical className="w-5 h-5" />
          </button>
        </div>
      </div>
      
      <div className="space-y-3">
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Amount:</span>
          <span className="font-semibold text-black">${lien.amount.toLocaleString()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Filing Date:</span>
          <span className="text-black">{new Date(lien.filingDate).toLocaleDateString()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Expiry Date:</span>
          <span className="text-black">{new Date(lien.expiryDate).toLocaleDateString()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Type:</span>
          <span className="text-black">{lien.type}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Debtor:</span>
          <span className="text-black">{lien.debtor}</span>
        </div>
      </div>
    
      <div className="mt-4 pt-4 border-t border-[#a39b8f]">
        <p className="text-sm text-[#3d3731] mb-2">Property Address:</p>
        <p className="text-sm text-black">{lien.propertyAddress}</p>
      </div>
    </div>
  );
};

export default LienCard;