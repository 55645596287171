import React from 'react';

const LiensTabs = ({ selectedFilter, setSelectedFilter }) => {
  return (
    <div className="flex border-b border-[#a39b8f] bg-[#e9e4dc] px-8">
      {['All Liens', 'Active', 'Pending', 'Expired'].map((tab) => (
        <button
          key={tab}
          onClick={() => setSelectedFilter(tab)}
          className={`px-4 py-4 ${
            tab === selectedFilter
              ? 'border-b-2 border-black text-black'
              : 'text-[#3d3731] hover:text-black'
          } transition-colors duration-150 text-sm font-medium`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default LiensTabs;