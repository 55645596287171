import React from 'react';

const StepIndicator = ({ currentStep, totalSteps, progress }) => (
  <div>
    <div className="flex items-center justify-center">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <div className="relative w-24 mx-2">
              <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-[8px] bg-[#d8d1c7]" />
              <div 
                className={`absolute top-1/2 transform -translate-y-1/2 h-[8px] bg-black transition-all duration-300`}
                style={{ 
                  width: index < currentStep ? '100%' : 
                         index === currentStep ? `${(progress * 100)}%` : 
                         '0%'
                }}
              />
            </div>
          )}
          <div className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-300 ${
            index < currentStep ? 'bg-black text-[#e9e4dc]' :
            index === currentStep ? 'bg-[#d8d1c7] text-black' :
            'bg-[#e9e4dc] text-[#a39b8f]'
          }`}>
            <span className="text-sm">{index + 1}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
    <div className="h-[1px] bg-[#d8d1c7] mt-8 mb-8" />
  </div>
);

export default StepIndicator;