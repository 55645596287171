import React, { useState } from 'react';
import { Filter, Search } from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DashboardHeader from './LienHeader';
import DashboardTabs from './LiensTabs';
import LienCard from './LienCard';
import LienPagination from './LienPagination';
import LienDetailPage from './LienDetailPage';

const LienManagement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState('All Liens');
  const [searchQuery, setSearchQuery] = useState('');

  const liens = [
    {
      id: 1,
      lienholderName: "Atlanta Medical Center",
      caseNumber: "L-2024-001",
      amount: 75000,
      filingDate: "2024-01-15",
      expiryDate: "2024-07-15",
      status: "Active",
      type: "Medical",
      propertyAddress: "123 Main St, Atlanta, GA",
      debtor: "John Smith"
    },
    {
      id: 2,  // Changed ID
      lienholderName: "Grady Hospital",
      caseNumber: "L-2024-002",  // Changed case number
      amount: 85000,
      filingDate: "2024-01-16",
      expiryDate: "2024-07-16",
      status: "Active",
      type: "Medical",
      propertyAddress: "456 Oak St, Atlanta, GA",
      debtor: "Jane Doe"
    },
    {
      id: 3,  // Changed ID
      lienholderName: "Piedmont Hospital",
      caseNumber: "L-2024-003",  // Changed case number
      amount: 65000,
      filingDate: "2024-01-17",
      expiryDate: "2024-07-17",
      status: "Active",
      type: "Medical",
      propertyAddress: "789 Pine St, Atlanta, GA",
      debtor: "Bob Wilson"
    }
    // ... other liens
  ];

  const handleBack = () => {
    navigate('/dashboard'); // This will take us back to the main dashboard
  };

  // Check if we're on a lien detail page
  const isDetailView = location.pathname.includes('/liens/');

  if (isDetailView) {
    const lien = liens.find(l => l.id === parseInt(id));
    if (!lien) return null; // Handle case where lien is not found

    return (
      <LienDetailPage 
        lien={lien} 
        onBack={handleBack}
      />
    );
  }

  return (
    <div className="flex flex-col h-screen bg-[#d8d1c7]">
      <DashboardHeader />
      <DashboardTabs selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />

      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <div className="relative w-64">
              <input
                type="text"
                placeholder="Search liens..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 pl-10 bg-[#e9e4dc] border border-[#a39b8f] rounded-md text-[#3d3731] focus:outline-none focus:border-black"
              />
              <Search className="w-4 h-4 absolute left-3 top-3 text-[#3d3731]" />
            </div>
            <button className="flex items-center px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150">
              <Filter className="w-4 h-4 mr-2" />
              Filters
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {liens.map((lien) => (
              <LienCard key={lien.id} lien={lien} />
            ))}
          </div>
        </div>
      </div>

      <LienPagination />
    </div>
  );
};

export default LienManagement;