import { S3Client, PutObjectCommand, DeleteObjectCommand, ListObjectsCommand } from '@aws-sdk/client-s3';
import { supabase } from './supabase';

class S3UploadService {
  constructor() {
    this.s3Client = new S3Client({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
      }
    });
  }

  async getUserId(email) {
    try {
      const { data: user, error } = await supabase
        .from('users')
        .select('id')
        .eq('email', email)
        .single();

      if (error) throw error;
      return user.id;
    } catch (error) {
      console.error('Error getting user ID:', error);
      throw new Error('Failed to get user ID');
    }
  }

  async uploadFile(file, userEmail, folder = 'documents') {
    try {
      // Get user ID from users table
      const userId = await this.getUserId(userEmail);
      if (!userId) {
        throw new Error('User not found');
      }

      // Generate a unique file name
      const fileExt = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;
      
      // Organize files by user ID and filename
      const key = `users/${userId}/${uniqueFileName}`;

      const uploadParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: key,
        Body: file,
        ContentType: file.type,
        Metadata: {
          originalName: file.name,
          uploadedBy: userId,
          uploadDate: new Date().toISOString()
        }
      };

      await this.s3Client.send(new PutObjectCommand(uploadParams));

      return {
        key,
        fileName: uniqueFileName,
        originalName: file.name,
        size: file.size,
        type: file.type,
        url: this.getFileUrl(key)
      };
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw new Error('Failed to upload file');
    }
  }

  async deleteFile(key) {
    try {
      const deleteParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: key
      };

      await this.s3Client.send(new DeleteObjectCommand(deleteParams));
      return true;
    } catch (error) {
      console.error('Error deleting from S3:', error);
      throw new Error('Failed to delete file');
    }
  }

  async listUserFiles(userEmail, folder = 'documents') {
    try {
      // Get user ID from users table
      const userId = await this.getUserId(userEmail);
      if (!userId) {
        throw new Error('User not found');
      }

      const listParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Prefix: `users/${userId}/`
      };

      const response = await this.s3Client.send(new ListObjectsCommand(listParams));
      return response.Contents?.map(item => ({
        key: item.Key,
        size: item.Size,
        lastModified: item.LastModified,
        url: this.getFileUrl(item.Key)
      })) || [];
    } catch (error) {
      console.error('Error listing S3 files:', error);
      throw new Error('Failed to list files');
    }
  }

  getFileUrl(key) {
    return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
  }
}

export const s3Service = new S3UploadService();

// Helper functions
export const validateFile = (file, options = {}) => {
  const {
    maxSizeMB = 10,
    allowedTypes = null,
  } = options;

  const maxSize = maxSizeMB * 1024 * 1024;
  
  if (file.size > maxSize) {
    throw new Error(`File size must be less than ${maxSizeMB}MB`);
  }

  if (allowedTypes && !allowedTypes.includes(file.type)) {
    throw new Error(`File type ${file.type} is not allowed`);
  }

  return true;
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};