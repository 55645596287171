import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { supabase } from '../../backend/supabase';
import { handleReferralSubmit } from '../../backend/referralService';
import StepIndicator from '../shared/StepIndicator';
import ContactDetailsStep from './ContactDetailsStep';
import CaseDetailsStep from './CaseDetailsStep';
import { FilesStep, SubmitStep } from './FilesAndSubmitSteps';

const ReferralForm = () => {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    firm: ''
  });
  const [errors, setErrors] = useState({});

  const [selectedSubject, setSelectedSubject] = useState('');
  const [caseDetails, setCaseDetails] = useState('');
  const [step2Phase, setStep2Phase] = useState(1);

  const [files, setFiles] = useState([]);
  const [compensationPercentage, setCompensationPercentage] = useState(25);
  const [acceptanceDeadline, setAcceptanceDeadline] = useState(null);
  const [step3Phase, setStep3Phase] = useState(1);

  const [currentStep, setCurrentStep] = useState(1);
  const [stepProgress, setStepProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

// ReferralForm.js - continued
useEffect(() => {
  const checkAuth = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error || !user) {
      console.error('Auth check failed:', error);
      navigate('/signup/login');
    }
  };
  checkAuth();
}, [navigate]);

useEffect(() => {
  // Progress calculations
  if (currentStep === 1) {
    const isFormValid = Object.values(formData).every(field => field.trim() !== '') &&
                     Object.values(errors).every(error => error === '');
    
    if (isFormValid) {
      setStepProgress(1);
    } else {
      const validFields = Object.entries(formData).filter(([key, value]) => 
        value.trim() !== '' && !errors[key]
      ).length;
      setStepProgress(validFields / Object.keys(formData).length);
    }
  } else if (currentStep === 2) {
    if (step2Phase === 1) {
      setStepProgress(selectedSubject ? 0.5 : 0);
    } else {
      setStepProgress(caseDetails.trim() ? 1 : 0.5);
    }
  } else if (currentStep === 3) {
    if (step3Phase === 1) {
      setStepProgress(0.5);
    } else {
      setStepProgress(acceptanceDeadline ? 1 : 0.5);
    }
  } else if (currentStep === 4) {
    setStepProgress(1);
  }
}, [currentStep, formData, errors, step2Phase, step3Phase, selectedSubject, caseDetails, files, acceptanceDeadline]);

const validateField = (name, value) => {
  switch (name) {
    case 'email':
      return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Invalid email address' : '';
    case 'phone':
      return !/^\+?[1-9][0-9]{7,14}$/.test(value.replace(/\D/g, '')) ? 'Invalid phone number' : '';
    case 'name':
    case 'firm':
      return !value.trim() ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required` : '';
    default:
      return '';
  }
};

const handleInputChange = (field, value) => {
  setFormData(prev => ({
    ...prev,
    [field]: value
  }));
  
  if (errors[field]) {
    setErrors(prev => ({
      ...prev,
      [field]: ''
    }));
  }
};

const handleNext = () => {
  if (currentStep === 1) {
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      newErrors[key] = validateField(key, value);
    });
    
    setErrors(newErrors);
    if (Object.values(newErrors).every(error => error === '')) {
      setCurrentStep(2);
      setStepProgress(0);
    }
  } else if (currentStep === 2) {
    if (step2Phase === 1 && selectedSubject) {
      setStep2Phase(2);
    } else if (step2Phase === 2 && caseDetails.trim()) {
      setCurrentStep(3);
    }
  } else if (currentStep === 3) {
    if (step3Phase === 1) {
      setStep3Phase(2);
    } else if (step3Phase === 2 && acceptanceDeadline) {
      setCurrentStep(4);
    }
  }
};

const handleBack = () => {
  if (currentStep === 2) {
    if (step2Phase === 2) {
      setStep2Phase(1);
    } else {
      setCurrentStep(1);
    }
  } else if (currentStep === 3) {
    if (step3Phase === 2) {
      setStep3Phase(1);
    } else {
      setCurrentStep(2);
      setStep2Phase(2);
    }
  } else if (currentStep === 4) {
    setCurrentStep(3);
    setStep3Phase(2);
  } else if (currentStep === 1) {
    navigate(-1);
  }
};

const handleSubmit = async () => {
  setLoading(true);
  setError('');

  try {
    const submissionData = {
      ...formData,
      selectedSubject,
      caseDetails,
      compensationPercentage,
      acceptanceDeadline
    };

    const result = await handleReferralSubmit(submissionData, files);
    
    if (!result.success) {
      throw new Error(result.error);
    }

    navigate('/dashboard');
  } catch (error) {
    console.error('Submission failed:', error);
    setError(error.message || 'Failed to submit referral');
    throw error;
  } finally {
    setLoading(false);
  }
};

const getPageTitle = () => {
  if (currentStep === 3) return "Anything to add?";
  if (currentStep === 4) return "Submit!";
  return "Submit A Referral";
};

return (
  <div className="min-h-screen bg-[#e9e4dc] flex flex-col pt-4">
    <div className="max-w-5xl mx-auto w-full px-6">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={handleBack}
          className="inline-flex items-center text-[#3d3731] hover:text-black transition-colors duration-150 -ml-6"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          <span className="text-lg">Back</span>
        </button>
        <h1 className="text-3xl font-bold text-black">
          {getPageTitle()}
        </h1>
        <div className="w-[100px]"></div>
      </div>

      <p className="text-center text-[#3d3731] mb-6">
        Please fill the form below to submit a referral. Feel free to add as much detail as needed.
      </p>

      <div className="bg-white rounded-2xl shadow-sm p-8">
        <StepIndicator currentStep={currentStep} totalSteps={4} progress={stepProgress} />

        {currentStep === 1 && (
          <ContactDetailsStep
            formData={formData}
            errors={errors}
            onInputChange={handleInputChange}
            onInputBlur={(field) => {
              setErrors(prev => ({
                ...prev,
                [field]: validateField(field, formData[field])
              }));
            }}
          />
        )}

        {currentStep === 2 && (
          <CaseDetailsStep
            phase={step2Phase}
            selectedSubject={selectedSubject}
            caseDetails={caseDetails}
            onSubjectSelect={setSelectedSubject}
            onDetailsChange={setCaseDetails}
          />
        )}

        {currentStep === 3 && (
          <FilesStep
            phase={step3Phase}
            files={files}
            onFileUpload={setFiles}
            compensationPercentage={compensationPercentage}
            acceptanceDeadline={acceptanceDeadline}
            onCompensationChange={setCompensationPercentage}
            onDeadlineChange={setAcceptanceDeadline}
          />
        )}

        {currentStep === 4 && (
          <SubmitStep 
            formData={{
              ...formData,
              selectedSubject,
              caseDetails,
              compensationPercentage,
              acceptanceDeadline
            }}
            files={files}
            onSubmit={handleSubmit}
            onBack={handleBack}
          />
        )}

        {currentStep !== 4 && (
          <div className="flex justify-between mt-8">
            {currentStep > 1 && (
              <button
                onClick={handleBack}
                className="px-6 py-2.5 text-[#3d3731] rounded-full transition-colors duration-150 text-sm font-medium border border-[#d8d1c7] hover:border-[#a39b8f]"
              >
                Previous step
              </button>
            )}
            <div className={currentStep === 1 ? "w-full flex justify-end" : "flex-1"} />
            <button
              onClick={handleNext}
              disabled={
                (currentStep === 1 && (!Object.values(formData).every(field => field.trim() !== '') || 
                                  Object.values(errors).some(error => error !== ''))) ||
                (currentStep === 2 && step2Phase === 1 && !selectedSubject) ||
                (currentStep === 2 && step2Phase === 2 && !caseDetails.trim()) ||
                (currentStep === 3 && step3Phase === 2 && !acceptanceDeadline)
              }
              className={`px-6 py-2.5 text-[#e9e4dc] rounded-full transition-colors duration-150 text-sm font-medium min-w-[120px] ${
                ((currentStep === 1 && Object.values(formData).every(field => field.trim() !== '') && 
                  Object.values(errors).every(error => error === '')) ||
                 (currentStep === 2 && ((step2Phase === 1 && selectedSubject) || 
                                    (step2Phase === 2 && caseDetails.trim()))) ||
                 (currentStep === 3 && ((step3Phase === 1) || 
                                    (step3Phase === 2 && acceptanceDeadline))))
                  ? 'bg-black hover:bg-[#3d3731]'
                  : 'bg-[#d8d1c7] cursor-not-allowed'
              }`}
            >
              Next step
            </button>
          </div>
        )}
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div className="mt-8 p-4 bg-gray-100 rounded-lg text-sm">
          <h3 className="font-semibold mb-2">Debug Information</h3>
          <pre className="overflow-auto">
            {JSON.stringify({
              currentStep,
              stepProgress,
              formData,
              errors,
              step2Phase,
              selectedSubject,
              caseDetails,
              step3Phase,
              filesCount: files.length,
              compensationPercentage,
              acceptanceDeadline
            }, null, 2)}
          </pre>
        </div>
      )}
    </div>
  </div>
);
};

export default ReferralForm;