import React from 'react';

const LienPagination = () => {
  return (
    <div className="flex items-center justify-between px-8 py-4 bg-[#e9e4dc] border-t border-[#a39b8f]">
      <div className="flex items-center text-sm text-[#3d3731]">
        <span>Showing page 1 of 256</span>
      </div>
      
      <div className="flex items-center space-x-1">
        <button className="px-3 py-1 rounded-md text-sm bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]">
          Previous
        </button>
        
        {[1, 2, 3, '...', 255, 256].map((page, index) => (
          <button
            key={index}
            className={`px-3 py-1 rounded-md text-sm ${
              page === 1
                ? 'bg-black text-white'
                : page === '...'
                ? 'bg-transparent text-[#3d3731] cursor-default'
                : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]'
            }`}
          >
            {page}
          </button>
        ))}
        
        <button className="px-3 py-1 rounded-md text-sm bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]">
          Next
        </button>
      </div>
    </div>
  );
};

export default LienPagination;