import React, { useEffect } from 'react';
import { Terminal, Gavel, Megaphone, Settings } from 'lucide-react';

const CaseSubjectOption = ({ icon: Icon, label, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center p-6 rounded-lg border-2 transition-all duration-150 w-full ${
      selected 
        ? 'border-black bg-black/5' 
        : 'border-[#d8d1c7] hover:border-[#a39b8f]'
    }`}
  >
    <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
      selected ? 'bg-black' : 'bg-[#f5f1eb]'
    }`}>
      <Icon className={`w-6 h-6 ${selected ? 'text-white' : 'text-[#a39b8f]'}`} />
    </div>
    <span className={`ml-4 font-medium ${selected ? 'text-black' : 'text-[#3d3731]'}`}>
      {label}
    </span>
  </button>
);

const CaseDetailsStep = ({ 
  phase,
  selectedSubject,
  caseDetails,
  onSubjectSelect,
  onDetailsChange 
}) => {
  const subjects = [
    { id: 'divorce', label: 'Divorce', icon: Terminal },
    { id: 'injury', label: 'Personal Injury', icon: Gavel },
    { id: 'civil', label: 'Civil', icon: Megaphone },
    { id: 'other', label: 'Other', icon: Settings }
  ];

  useEffect(() => {
    console.log('CaseDetailsStep mounted with:', { 
      phase,
      selectedSubject, 
      caseDetails
    });
  }, [phase, selectedSubject, caseDetails]);

  const handleSubjectSelect = (subjectId) => {
    console.log('Subject selected:', subjectId);
    onSubjectSelect(subjectId);
  };

  const handleDetailsChange = (value) => {
    console.log('Case details updated:', value);
    onDetailsChange(value);
  };

  if (phase === 1) {
    return (
      <div>
        <h2 className="text-xl font-semibold text-black mb-2">
          Case subject
        </h2>
        <p className="text-[#3d3731] mb-6">
          Update or confirm the subject of the case.
        </p>
        
        <div className="grid grid-cols-2 gap-6">
          {subjects.map(subject => (
            <CaseSubjectOption 
              key={subject.id}
              icon={subject.icon} 
              label={subject.label} 
              selected={selectedSubject === subject.id}
              onClick={() => handleSubjectSelect(subject.id)}
            />
          ))}
        </div>

        {/* Selection Hint */}
        {!selectedSubject && (
          <div className="mt-4 text-sm text-[#3d3731]">
            Please select a case subject to continue
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-semibold text-black mb-2">
        Case details
      </h2>
      <p className="text-[#3d3731] mb-6">
        Update the details of this case.
      </p>
      
      <textarea
        value={caseDetails}
        onChange={(e) => handleDetailsChange(e.target.value)}
        className="w-full h-48 p-4 border border-[#d8d1c7] rounded-lg bg-[#f5f1eb] placeholder-[#a39b8f] text-[#3d3731] focus:outline-none focus:ring-1 focus:ring-black focus:border-black transition-colors duration-150 resize-none"
        placeholder="Provide any updates or additional details about the case..."
      />

      {/* Input Guidance */}
      <div className="mt-4 text-sm text-[#3d3731]">
        <h3 className="font-medium mb-2">Include relevant updates such as:</h3>
        <ul className="list-disc pl-5 space-y-1">
          <li>Changes to case circumstances</li>
          <li>New developments</li>
          <li>Additional context</li>
          <li>Current status details</li>
          <li>Specific requirements or considerations</li>
        </ul>

        {/* Character Count */}
        <div className="mt-4 text-right">
          {caseDetails.length}/1000 characters
        </div>
      </div>
    </div>
  );
};

export default CaseDetailsStep;