import React from 'react';

const TrustedFirms = ({ isBlackAndWhite }) => {
  const firms = [
    'REPSOL', 'schönherr', 'Vinson&Elkins', 'ReedSmith', 'pwc', 'MACFARLANES', 'BRID'
  ];

  const containerStyle = isBlackAndWhite
    ? 'bg-white text-black'
    : 'bg-[#0f0b08] text-[#d8d1c7]';

  const gradientStyle = isBlackAndWhite
    ? 'bg-gradient-to-b from-gray-400 via-gray-600 to-gray-800 text-transparent bg-clip-text'
    : 'text-[#a39b8f]';

  const sliderStyle = isBlackAndWhite
    ? 'bg-white'
    : 'bg-transparent';

  const firmTextStyle = isBlackAndWhite
    ? 'text-gray-800'
    : 'text-[#8a8178]';

  return (
    <div className={`py-6 ${containerStyle}`}>
      <div className="container mx-auto px-3">
        <h2 className="text-xl font-bold text-center mb-3">
          Trusted by 20+ firms in the{' '}
          <span className={gradientStyle}>
            Greater Atlanta Area
          </span>
        </h2>
        <div className={`overflow-hidden ${sliderStyle}`}>
          <div className="flex animate-slide py-2">
            {[...firms, ...firms].map((firm, index) => (
              <div key={index} className={`flex-shrink-0 mx-5 text-lg font-bold whitespace-nowrap ${firmTextStyle}`}>
                {firm}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedFirms;