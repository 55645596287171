import React, { useState, useRef, useEffect } from 'react';
import { Trash2, Edit2, Check, AlertCircle, Loader, X } from 'lucide-react';
import { deleteReferral } from '../../backend/referralService';
import EditReferralModal from '../referrals/EditReferralModal';

// Delete Confirmation Dialog Component
const DeleteConfirmDialog = ({ isOpen, onClose, onConfirm, loading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#e9e4dc] p-6 rounded-lg max-w-md w-full">
        <div className="flex items-center mb-4">
          <AlertCircle className="w-6 h-6 text-red-500 mr-2" />
          <h3 className="text-lg font-semibold text-black">Confirm Deletion</h3>
        </div>
        
        <p className="text-[#3d3731] mb-6">
          Are you sure you want to delete this referral? This action cannot be undone.
        </p>
        
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={loading}
            className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={loading}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center"
          >
            {loading ? (
              <>
                <Loader className="w-4 h-4 mr-2 animate-spin" />
                Deleting...
              </>
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export const CustomerRow = ({ 
  id,
  logo,
  name,
  status,
  contact,
  email,
  phone,
  position,
  progress,
  case_id,
  non_user_id,
  caseDetails,
  compensationTerms,
  acceptanceDeadline,
  onStatusUpdate,
  onRefresh,
  selected,
  onSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const statuses = [
    'pending',
    'received',
    'viewed',
    'renegotiating',
    'accepted',
    'rejected',
    'expired',
    'litigation',
    'billing',
    'completed'
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteError(null);
    
    try {
      console.log('Deleting referral:', id);
      const result = await deleteReferral(id);
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to delete referral');
      }
      
      setShowDeleteConfirm(false);
      if (onRefresh) {
        await onRefresh();
      }
    } catch (error) {
      console.error('Error deleting referral:', error);
      setDeleteError(error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getStatusStyle = (status) => {
    const styles = {
      pending: 'bg-[#d8d1c7] text-[#3d3731]',
      received: 'bg-[#c5beb2] text-[#3d3731]',
      viewed: 'bg-[#c5beb2] text-[#3d3731]',
      renegotiating: 'bg-[#a39b8f] text-[#e9e4dc]',
      accepted: 'bg-[#3d3731] text-[#e9e4dc]',
      rejected: 'bg-red-200 text-red-800',
      expired: 'bg-[#a39b8f] text-[#e9e4dc]',
      litigation: 'bg-[#3d3731] text-[#e9e4dc]',
      billing: 'bg-[#3d3731] text-[#e9e4dc]',
      completed: 'bg-black text-white'
    };
    return styles[status.toLowerCase()] || styles.pending;
  };

  const handleToggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const dropdownHeight = 320;
      
      const showAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
      
      setDropdownPosition({
        left: rect.left + scrollLeft,
        top: showAbove 
          ? rect.top + scrollTop - dropdownHeight - 8
          : rect.bottom + scrollTop + 8
      });
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <tr className="bg-[#f5f1eb] border-b border-[#d8d1c7]">
        <td className="px-6 py-4">
          <input 
            type="checkbox" 
            checked={selected}
            onChange={(e) => onSelect(id, e.target.checked)}
            className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" 
          />
        </td>
        <td className="px-6 py-4">
          <div className="flex items-center">
            <img className="h-10 w-10 rounded-full" src={logo} alt="" />
            <div className="ml-4">
              <div className="text-sm font-medium text-black">{name}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={handleToggleDropdown}
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(status)}`}
            >
              {status}
            </button>
            
            {isOpen && (
              <div
                ref={dropdownRef}
                style={{
                  position: 'fixed',
                  left: dropdownPosition.left,
                  top: dropdownPosition.top,
                  width: '12rem'
                }}
                className="rounded-lg bg-white shadow-lg border border-[#d8d1c7] z-50 max-h-80 overflow-y-auto"
              >
                <div className="py-1">
                  {statuses.map((statusOption) => (
                    <button
                      key={statusOption}
                      onClick={() => {
                        onStatusUpdate(id, statusOption);
                        setIsOpen(false);
                      }}
                      className="w-full flex items-center px-4 py-2 text-sm hover:bg-[#f5f1eb] transition-colors duration-150"
                    >
                      <span className={`flex-1 px-2 py-0.5 rounded-full text-xs font-semibold ${getStatusStyle(statusOption)}`}>
                        {statusOption}
                      </span>
                      {statusOption === status.toLowerCase() && (
                        <Check className="w-4 h-4 ml-2 text-[#3d3731]" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm text-[#3d3731]">{contact}</div>
          <div className="text-sm text-[#a39b8f]">{email}</div>
          <div className="text-sm text-[#a39b8f]">{phone}</div>
        </td>
        <td className="px-6 py-4">
          <div className="w-full bg-[#d8d1c7] rounded-full h-2.5">
            <div 
              className="bg-[#3d3731] h-2.5 rounded-full transition-all duration-300" 
              style={{ width: `${progress}%` }}
            />
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button 
            onClick={() => setShowDeleteConfirm(true)}
            className="text-[#a39b8f] hover:text-[#3d3731] mr-2"
          >
            <Trash2 size={20} />
          </button>
          <button 
            onClick={() => setShowEditModal(true)}
            className="text-[#a39b8f] hover:text-[#3d3731]"
          >
            <Edit2 size={20} />
          </button>
        </td>
      </tr>

      {deleteError && (
        <tr>
          <td colSpan={6}>
            <div className="m-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-center text-red-700">
              <AlertCircle className="w-5 h-5 mr-2" />
              {deleteError}
              <button 
                onClick={() => setDeleteError(null)}
                className="ml-auto"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </td>
        </tr>
      )}

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        loading={deleteLoading}
      />

      {/* Edit Modal */}
      {showEditModal && (
        <EditReferralModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          referral={{
            id,
            contact,
            email,
            phone,
            name,
            status,
            caseDetails,
            compensationTerms,
            acceptanceDeadline,
            case_id,        // Critical: Pass these IDs
            non_user_id,    // Critical: Pass these IDs
            files: []
          }}
          onUpdate={() => {
            if (onRefresh) {
              onRefresh();
            }
            setShowEditModal(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerRow;