import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const TestimonialCard = ({ name, role, comment, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-white border border-gray-200'
    : 'bg-[#1a1410] border border-[#2a2118]';

  const nameColor = isBlackAndWhite ? 'text-gray-800' : 'text-[#d8d1c7]';
  const roleColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#a39b8f]';
  const commentColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#8a8178]';

  return (
    <div className={`p-6 rounded-lg ${cardStyle}`}>
      <p className={`mb-4 ${commentColor}`}>{comment}</p>
      <div className="flex items-center">
        <img src="/api/placeholder/40/40" alt={name} className="w-10 h-10 rounded-full mr-4" />
        <div>
          <h4 className={`font-semibold ${nameColor}`}>{name}</h4>
          <p className={`text-sm ${roleColor}`}>{role}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = ({ isBlackAndWhite = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonials = [
    { name: "Amanda Russel", role: "Kalka Law Group", comment: "Coming Soon!" },
    { name: "First/Last Name", role: "Law Firm Name", comment: "Coming Soon!" },
    { name: "First/Last Name", role: "Law Firm Name", comment: "Coming Soon!" },
    { name: "First/Last Name", role: "Law Firm Name", comment: "Coming Soon!" },
  ];

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const containerStyle = isBlackAndWhite
    ? 'bg-gray-100'
    : 'bg-[#0f0b08] text-[#d8d1c7]';

  const headingStyle = isBlackAndWhite
    ? 'text-gray-800'
    : 'text-[#d8d1c7]';

  const buttonStyle = isBlackAndWhite
    ? 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    : 'bg-[#2a2118] text-[#d8d1c7] hover:bg-[#3d3326]';

  return (
    <section className={`py-16 ${containerStyle}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-3xl font-bold mb-8 text-center ${headingStyle}`}>
          Hear what our customers say
        </h2>
        <div className="relative">
          <div className="flex overflow-x-hidden">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="w-full flex-shrink-0 transition-transform duration-300 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                <TestimonialCard {...testimonial} isBlackAndWhite={isBlackAndWhite} />
              </div>
            ))}
          </div>
          <button
            onClick={prevTestimonial}
            className={`absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full ${buttonStyle}`}
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextTestimonial}
            className={`absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full ${buttonStyle}`}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;