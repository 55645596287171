// authhandlers.js
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { supabase } from '../../backend/supabase';

export const useAuthHandlers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      try {
        // Get user info from Google
        const userInfoResponse = await fetch(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
        );
        const userInfo = await userInfoResponse.json();

        // Store user info temporarily
        localStorage.setItem('tempAuthUser', JSON.stringify({
          email: userInfo.email,
          user_metadata: {
            given_name: userInfo.given_name,
            family_name: userInfo.family_name,
            picture: userInfo.picture
          }
        }));

        // Sign in with Supabase - AuthCallback will handle the redirect
        const { data: authData, error: authError } = await supabase.auth.signInWithOAuth({
          provider: 'google',
          options: {
            queryParams: {
              access_type: 'offline',
              prompt: 'consent',
            },
            redirectTo: `${window.location.origin}/auth/callback`
          }
        });

        if (authError) throw authError;

        if (authData?.url) {
          window.location.href = authData.url;
        }
      } catch (error) {
        console.error('Google login error:', error);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      setLoading(false);
    }
  });

  const handleLinkedInLogin = async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'linkedin_oidc',
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          redirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;
      
      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('LinkedIn login error:', error);
      setLoading(false);
    }
  };

  return {
    googleLogin,
    handleLinkedInLogin,
    loading
  };
};