import React, { useState } from 'react';
import { 
  Files, 
  FolderOpen, 
  Clock,
  Plus,
  Search,
  Grid,
  List,
  Filter,
  Download,
  Share2,
  MoreVertical,
  Users,
  Trash2,
  History
} from 'lucide-react';

const DocumentDashboard = () => {
  const [viewType, setViewType] = useState('grid');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showVersions, setShowVersions] = useState(false);

  // Example documents data structure
  const documents = [
    {
      id: 1,
      name: 'Contract Agreement v3.docx',
      type: 'contract',
      lastModified: '2024-01-15',
      size: '2.4 MB',
      versions: 5,
      sharedWith: ['John D.', 'Sarah M.'],
      status: 'active'
    },
    // ... more documents
  ];

  return (
    <div className="flex h-screen bg-[#f5f1eb]">
      {/* Left Sidebar - Document Navigation */}
      <div className="w-64 bg-[#e9e4dc] border-r border-[#d8d1c7] p-4">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-black">Documents</h2>
          <button className="p-2 hover:bg-[#d8d1c7] rounded-md">
            <Plus className="w-5 h-5 text-[#3d3731]" />
          </button>
        </div>

        <div className="space-y-2">
          <button className="w-full flex items-center px-3 py-2 rounded-md bg-[#d8d1c7] text-black">
            <Files className="w-4 h-4 mr-3" />
            All Documents
          </button>
          <button className="w-full flex items-center px-3 py-2 rounded-md hover:bg-[#d8d1c7] text-[#3d3731]">
            <Clock className="w-4 h-4 mr-3" />
            Recent
          </button>
          <button className="w-full flex items-center px-3 py-2 rounded-md hover:bg-[#d8d1c7] text-[#3d3731]">
            <Share2 className="w-4 h-4 mr-3" />
            Shared
          </button>
        </div>

        <div className="mt-8">
          <h3 className="text-sm font-medium text-[#3d3731] mb-2">FOLDERS</h3>
          <div className="space-y-1">
            <button className="w-full flex items-center px-3 py-2 rounded-md hover:bg-[#d8d1c7] text-[#3d3731]">
              <FolderOpen className="w-4 h-4 mr-3" />
              Contracts
            </button>
            <button className="w-full flex items-center px-3 py-2 rounded-md hover:bg-[#d8d1c7] text-[#3d3731]">
              <FolderOpen className="w-4 h-4 mr-3" />
              Legal Briefs
            </button>
            <button className="w-full flex items-center px-3 py-2 rounded-md hover:bg-[#d8d1c7] text-[#3d3731]">
              <FolderOpen className="w-4 h-4 mr-3" />
              Liens
            </button>
            {/* Add more folders */}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Toolbar */}
        <div className="h-16 border-b border-[#d8d1c7] bg-white px-6 flex items-center justify-between">
          <div className="flex items-center flex-1 max-w-md">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search documents..."
                className="w-full pl-10 pr-4 py-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:border-[#3d3731]"
              />
              <Search className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-[#a39b8f]" />
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <button 
              onClick={() => setViewType('grid')}
              className={`p-2 rounded-md ${viewType === 'grid' ? 'bg-[#d8d1c7]' : 'hover:bg-[#f5f1eb]'}`}
            >
              <Grid className="w-4 h-4" />
            </button>
            <button
              onClick={() => setViewType('list')}
              className={`p-2 rounded-md ${viewType === 'list' ? 'bg-[#d8d1c7]' : 'hover:bg-[#f5f1eb]'}`}
            >
              <List className="w-4 h-4" />
            </button>
            <button className="p-2 hover:bg-[#f5f1eb] rounded-md">
              <Filter className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Document Grid/List */}
        <div className="flex-1 overflow-auto p-6">
          {viewType === 'grid' ? (
            <div className="grid grid-cols-4 gap-4">
              {documents.map((doc) => (
                <div
                  key={doc.id}
                  className="bg-white p-4 rounded-lg border border-[#d8d1c7] hover:shadow-md transition-shadow duration-200"
                  onClick={() => setSelectedFile(doc)}
                >
                  <div className="flex items-start justify-between mb-4">
                    <Files className="w-8 h-8 text-[#3d3731]" />
                    <button className="p-1 hover:bg-[#f5f1eb] rounded-md">
                      <MoreVertical className="w-4 h-4" />
                    </button>
                  </div>
                  <h3 className="font-medium text-black mb-1">{doc.name}</h3>
                  <div className="text-sm text-[#a39b8f] mb-2">
                    {doc.lastModified} • {doc.size}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex -space-x-2">
                      {doc.sharedWith.map((user, i) => (
                        <div
                          key={i}
                          className="w-6 h-6 rounded-full bg-[#d8d1c7] border-2 border-white"
                          title={user}
                        />
                      ))}
                    </div>
                    <span className="text-sm text-[#3d3731]">
                      {doc.versions} versions
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bg-white rounded-lg border border-[#d8d1c7]">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-[#d8d1c7]">
                    <th className="text-left p-4 text-[#3d3731] font-medium">Name</th>
                    <th className="text-left p-4 text-[#3d3731] font-medium">Modified</th>
                    <th className="text-left p-4 text-[#3d3731] font-medium">Size</th>
                    <th className="text-left p-4 text-[#3d3731] font-medium">Versions</th>
                    <th className="text-left p-4 text-[#3d3731] font-medium">Shared With</th>
                    <th className="text-left p-4 text-[#3d3731] font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((doc) => (
                    <tr
                      key={doc.id}
                      className="border-b border-[#d8d1c7] hover:bg-[#f5f1eb] cursor-pointer"
                      onClick={() => setSelectedFile(doc)}
                    >
                      <td className="p-4">
                        <div className="flex items-center">
                          <Files className="w-5 h-5 mr-3 text-[#3d3731]" />
                          <span className="font-medium text-black">{doc.name}</span>
                        </div>
                      </td>
                      <td className="p-4 text-[#3d3731]">{doc.lastModified}</td>
                      <td className="p-4 text-[#3d3731]">{doc.size}</td>
                      <td className="p-4 text-[#3d3731]">{doc.versions}</td>
                      <td className="p-4">
                        <div className="flex -space-x-2">
                          {doc.sharedWith.map((user, i) => (
                            <div
                              key={i}
                              className="w-6 h-6 rounded-full bg-[#d8d1c7] border-2 border-white"
                              title={user}
                            />
                          ))}
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="flex items-center space-x-2">
                          <button className="p-1 hover:bg-[#d8d1c7] rounded-md">
                            <History className="w-4 h-4" />
                          </button>
                          <button className="p-1 hover:bg-[#d8d1c7] rounded-md">
                            <Download className="w-4 h-4" />
                          </button>
                          <button className="p-1 hover:bg-[#d8d1c7] rounded-md">
                            <Share2 className="w-4 h-4" />
                          </button>
                          <button className="p-1 hover:bg-[#d8d1c7] rounded-md">
                            <MoreVertical className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Right Sidebar - Details/Versions */}
      {selectedFile && (
        <div className="w-80 bg-[#e9e4dc] border-l border-[#d8d1c7]">
          <div className="p-4">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-semibold text-black">Details</h3>
              <button 
                className="p-2 hover:bg-[#d8d1c7] rounded-md"
                onClick={() => setSelectedFile(null)}
              >
                <Trash2 className="w-4 h-4 text-[#3d3731]" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-sm text-[#3d3731]">File Name</label>
                <div className="font-medium text-black">{selectedFile.name}</div>
              </div>

              <div>
                <label className="text-sm text-[#3d3731]">Last Modified</label>
                <div className="font-medium text-black">{selectedFile.lastModified}</div>
              </div>

              <div>
                <label className="text-sm text-[#3d3731]">Size</label>
                <div className="font-medium text-black">{selectedFile.size}</div>
              </div>

              <div>
                <label className="text-sm text-[#3d3731]">Shared With</label>
                <div className="flex items-center mt-2">
                  <div className="flex -space-x-2 mr-2">
                    {selectedFile.sharedWith.map((user, i) => (
                      <div
                        key={i}
                        className="w-8 h-8 rounded-full bg-[#d8d1c7] border-2 border-white"
                        title={user}
                      />
                    ))}
                  </div>
                  <button className="p-1 hover:bg-[#d8d1c7] rounded-full">
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
              </div>

              <div>
                <button
                  onClick={() => setShowVersions(!showVersions)}
                  className="w-full mt-4 px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150"
                >
                  View Version History
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentDashboard;