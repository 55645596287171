import React from 'react';
import { Download, Plus } from 'lucide-react';

const LienHeader = () => {
  return (
    <div className="flex justify-between items-center py-4 px-8 border-b border-[#a39b8f] bg-[#e9e4dc]">
      <h1 className="text-2xl font-semibold text-black">Lien Management</h1>
      <div className="flex space-x-2">
        <button className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150 flex items-center">
          <Download className="w-4 h-4 mr-2" />
          Export
        </button>
        <button className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150 flex items-center">
          <Plus className="w-4 h-4 mr-2 text-white" />
          New Lien
        </button>
      </div>
    </div>
  );
};

export default LienHeader;