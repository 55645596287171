// components/signup/AttorneyProfileCompletion.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { completeAttorneyProfile } from '../../backend/userService';

const AttorneyProfileCompletion = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [formData, setFormData] = useState({
    firmName: '',
    practiceAreas: [],
    barId: '',
    yearsOfExperience: '',
    bio: '',
    website: '',
    officeAddress: '',
    phone: '',
    city: '',
    state: '',
    zipCode: ''
  });

  useEffect(() => {
    // Get stored auth user data
    const storedAuthUser = sessionStorage.getItem('tempAuthUser');
    if (!storedAuthUser) {
      navigate('/signup');
      return;
    }
    setAuthUser(JSON.parse(storedAuthUser));
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await completeAttorneyProfile(authUser, formData);
      // Clean up stored auth user data
      sessionStorage.removeItem('tempAuthUser');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error completing profile:', error);
      alert('Error completing profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePracticeAreasChange = (areas) => {
    setFormData(prev => ({
      ...prev,
      practiceAreas: areas
    }));
  };

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex flex-col items-center px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 tracking-wider" style={{
        WebkitTextStroke: '1px #1A1A1A',
        WebkitTextFillColor: 'transparent'
      }}>Complete Your Profile</h1>

      <form onSubmit={handleSubmit} className="w-full max-w-2xl space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
              FIRM NAME
            </label>
            <input
              type="text"
              name="firmName"
              value={formData.firmName}
              onChange={handleInputChange}
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
              BAR NUMBER
            </label>
            <input
              type="text"
              name="barId"
              value={formData.barId}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none"
            />
          </div>
        </div>

        {/* Practice Areas */}
        <div>
          <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
            PRACTICE AREAS
          </label>
          {/* Add practice areas selection component here */}
        </div>

        {/* Additional Fields */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
              YEARS OF EXPERIENCE
            </label>
            <input
              type="number"
              name="yearsOfExperience"
              value={formData.yearsOfExperience}
              onChange={handleInputChange}
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-sm mb-2 text-[#1A1A1A] tracking-wider">
              WEBSITE
            </label>
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none"
            />
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className="w-full px-6 py-3 bg-[#1A1A1A] text-[#C5C0BB] tracking-wider hover:bg-[#2A2A2A]"
        >
          {loading ? 'SAVING...' : 'COMPLETE REGISTRATION'}
        </button>
      </form>
    </div>
  );
};

export default AttorneyProfileCompletion;