// components/signup/registration/RegistrationPhases/BarVerification.js
import React from 'react';
import { FormInput } from '../FormInput';

export const BarVerification = ({ formData, onChange, validationErrors }) => (
  <div className="space-y-6">
    <FormInput
      label="BAR ID"
      name="barId"
      value={formData.barId}
      onChange={onChange}
      error={validationErrors.barId}
      placeholder="Enter your Bar ID"
      required
    />

    <div className="flex items-center gap-2 text-[#1A1A1A]">
      <input
        type="checkbox"
        name="publicReferrals"
        checked={formData.publicReferrals}
        onChange={onChange}
        className="w-4 h-4 border-[#1A1A1A] rounded-none focus:ring-[#1A1A1A]"
      />
      <span className="text-sm tracking-wider">
        BE VISIBLE FOR PUBLIC REFERRALS
      </span>
    </div>
  </div>
);

console.log('BarVerification loaded');
