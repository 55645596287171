import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHandlers } from './authhandlers';
import { supabase } from '../../backend/supabase';

const LoginForm = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const { googleLogin, handleLinkedInLogin, loading: authLoading } = useAuthHandlers();
  
  const [formData, setFormData] = useState({
    emailPhone: '',
    password: '',
    rememberMe: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    if (!role) {
      navigate('/signup');
    }
    setUserRole(role);
  }, [navigate]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.emailPhone,
        password: formData.password,
      });

      if (error) throw error;
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCreateAccount = () => {
    navigate('/signup/register');
  };

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex flex-col items-center px-4 py-8">
      {/* Top Navigation */}
      <div className="fixed top-0 left-0 right-0 flex items-center p-4 text-[#1A1A1A] bg-[#C5C0BB]">
        <button 
          onClick={() => navigate(-1)}
          className="text-sm font-light hover:text-[#2A2A2A] flex items-center gap-2 tracking-wider"
        >
          ← BACK
        </button>
      </div>

      {/* Logo */}
      <div className="flex items-center gap-2 mb-8 mt-16">
        <div className="w-8 h-8 bg-[#1A1A1A] rounded-full flex items-center justify-center">
          <div className="w-4 h-4 border-2 border-[#C5C0BB] rounded-full"></div>
        </div>
        <span className="text-xl tracking-widest text-[#1A1A1A]">LAWDIE</span>
      </div>

      {/* Heading */}
      <h1 
        className="text-4xl font-bold mb-2 tracking-wider" 
        style={{
          WebkitTextStroke: '1px #1A1A1A',
          WebkitTextFillColor: 'transparent'
        }}
      >
        LOGIN
      </h1>
      <p className="text-[#1A1A1A] mb-1 tracking-wider">Welcome back.</p>
      <p className="text-[#1A1A1A] mb-6 tracking-wider uppercase">
        {userRole === 'attorney' ? 'Attorney Portal' : 'Client Portal'}
      </p>

      {/* Error Message */}
      {error && (
        <div className="w-full max-w-md mb-4 p-4 bg-red-100 border border-red-400 text-red-700">
          {error}
        </div>
      )}

      {/* Form */}
      <div className="w-full max-w-md">
        <form onSubmit={handleEmailLogin} className="space-y-4">
          {/* Email Input */}
          <div>
            <label className="block text-sm mb-1 text-[#1A1A1A] tracking-wider">
              EMAIL OR PHONE
            </label>
            <input
              type="text"
              name="emailPhone"
              value={formData.emailPhone}
              onChange={handleChange}
              disabled={loading || authLoading}
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none focus:ring-1 focus:ring-[#1A1A1A]"
            />
          </div>

          {/* Password Input */}
          <div>
            <label className="block text-sm mb-1 text-[#1A1A1A] tracking-wider">
              PASSWORD
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              disabled={loading || authLoading}
              className="w-full px-4 py-3 bg-transparent border border-[#1A1A1A] rounded-none focus:outline-none focus:ring-1 focus:ring-[#1A1A1A]"
            />
          </div>

          {/* Remember Me & Forgot Password */}
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2 text-[#1A1A1A]">
              <input
                type="checkbox"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
                disabled={loading || authLoading}
                className="w-4 h-4 border-[#1A1A1A] rounded-none focus:ring-[#1A1A1A]"
              />
              <span className="text-sm tracking-wider">REMEMBER ME</span>
            </div>
            <button 
              type="button" 
              className="text-sm tracking-wider hover:underline text-[#1A1A1A]"
              onClick={() => navigate('/forgot-password')}
            >
              FORGOT PASSWORD?
            </button>
          </div>

{/* Buttons Section */}
<div className="space-y-3 pt-4">
  {/* Main Login Button */}
  <button
    type="submit"
    disabled={loading || authLoading}
    className="w-full h-[50px] px-6 bg-transparent border border-[#1A1A1A] text-[#1A1A1A] tracking-wider hover:bg-[#1A1A1A] hover:text-[#C5C0BB] transition-colors disabled:opacity-50"
  >
    {loading ? 'LOGGING IN...' : 'LOGIN'}
  </button>

  {/* Social Login Buttons */}
  <div className="grid grid-cols-2 gap-3">
    <button
      type="button"
      onClick={googleLogin}
      disabled={loading || authLoading}
      className="h-[50px] px-6 bg-[#1A1A1A] text-[#C5C0BB] tracking-wider hover:bg-[#2A2A2A] disabled:opacity-50"
    >
      <div className="flex items-center justify-center">
        <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24" fill="currentColor">
          <path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"/>
        </svg>
        SIGN IN WITH GOOGLE
      </div>
    </button>

    {userRole === 'attorney' && (
      <button
        type="button"
        onClick={handleLinkedInLogin}
        disabled={loading || authLoading}
        className="h-[50px] px-6 bg-[#1A1A1A] text-[#C5C0BB] tracking-wider hover:bg-[#2A2A2A] disabled:opacity-50"
      >
        <div className="flex items-center justify-center">
          <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
          </svg>
          SIGN IN WITH LINKEDIN
        </div>
      </button>
    )}
  </div>
</div>
        </form>

        {/* Create Account Link */}
        <div className="mt-6 text-center">
          <p className="text-sm tracking-wider text-[#1A1A1A]">
            DON'T HAVE AN ACCOUNT?{' '}
            <button 
              onClick={handleCreateAccount}
              className="underline hover:no-underline"
            >
              CREATE ACCOUNT
            </button>
          </p>
        </div>

        {/* Social Links */}
        <div className="mt-8 flex justify-center gap-6">
          <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">Instagram</button>
          <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">LinkedIn</button>
          <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">Behance</button>
        </div>

        {/* Copyright */}
        <div className="mt-4 text-center text-xs tracking-wider text-[#1A1A1A]">
          ©Lawdie 2024
        </div>
      </div>
    </div>
  );
};

export default LoginForm;