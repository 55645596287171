import React from 'react';
import { Building, Mail, Phone, User } from 'lucide-react';
import FormInput from '../shared/FormInput';

const ContactDetailsStep = ({ formData, errors, onInputChange, onInputBlur }) => {
  const handlePhoneChange = (value) => {
    // Format phone number with proper mask (XXX) XXX - XXXX
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 10) {
      let formattedPhone = '';
      if (numericValue.length > 0) formattedPhone += '(' + numericValue.slice(0, 3);
      if (numericValue.length > 3) formattedPhone += ') ' + numericValue.slice(3, 6);
      if (numericValue.length > 6) formattedPhone += ' - ' + numericValue.slice(6, 10);
      onInputChange('phone', formattedPhone);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? '' : 'Please enter a valid email address';
  };

  const handleEmailBlur = () => {
    const emailError = validateEmail(formData.email);
    if (emailError) {
      onInputBlur('email', emailError);
    }
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3} - \d{4}$/;
    return phoneRegex.test(phone) ? '' : 'Please enter a valid phone number';
  };

  const handlePhoneBlur = () => {
    const phoneError = validatePhone(formData.phone);
    if (phoneError) {
      onInputBlur('phone', phoneError);
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold text-black mb-2">
        Contact details
      </h2>
      <p className="text-[#3d3731] mb-6">
        Edit the contact information for this referral.
      </p>
      
      <div className="grid grid-cols-2 gap-6">
        <FormInput
          icon={Building}
          label="FIRM"
          name="firm"
          value={formData.firm}
          onChange={(value) => onInputChange('firm', value)}
          onBlur={() => onInputBlur('firm')}
          error={errors.firm}
          required
          placeholder="Enter firm name"
        />
        <FormInput
          icon={Mail}
          label="EMAIL"
          name="email"
          type="email"
          value={formData.email}
          onChange={(value) => onInputChange('email', value)}
          onBlur={handleEmailBlur}
          error={errors.email}
          required
          placeholder="Enter email address"
        />
        <FormInput
          icon={Phone}
          label="PHONE NUMBER"
          name="phone"
          value={formData.phone}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          error={errors.phone}
          required
          placeholder="(XXX) XXX - XXXX"
        />
        <FormInput
          icon={User}
          label="CONTACT NAME"
          name="name"
          value={formData.name}
          onChange={(value) => onInputChange('name', value)}
          onBlur={() => onInputBlur('name')}
          error={errors.name}
          required
          placeholder="Enter contact name"
        />
      </div>

      {/* Input Requirements */}
      <div className="mt-6 text-sm text-[#3d3731]">
        <h3 className="font-medium mb-2">Input Requirements:</h3>
        <ul className="list-disc pl-5 space-y-1">
          <li>All fields are required</li>
          <li>Phone number must be in format: (XXX) XXX - XXXX</li>
          <li>Email must be a valid email address</li>
          <li>Firm name and Contact name cannot be empty</li>
        </ul>
      </div>
    </div>
  );
};

export default ContactDetailsStep;