import { supabase } from './supabase';

const formatPhoneNumber = (phone) => {
  const digitsOnly = phone.replace(/\D/g, '');
  if (digitsOnly.length === 10) {
    return `+1${digitsOnly}`;
  } else if (digitsOnly.length > 10) {
    return `+${digitsOnly}`;
  }
  return `+1${digitsOnly.padEnd(10, '0')}`;
};

export const calculateProgress = (status) => {
  const statusProgress = {
    'pending': 25,
    'received': 40,
    'viewed': 50,
    'renegotiating': 65,
    'accepted': 75,
    'litigation': 85,
    'billing': 95,
    'completed': 100,
    'rejected': 0,
    'expired': 0
  };
  return statusProgress[status.toLowerCase()] || 0;
};

export const fetchUserReferrals = async () => {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!user) throw new Error('No authenticated user');

    console.log('Fetching referrals for user:', user.id);

    const { data, error } = await supabase
      .from('referrals')
      .select(`
        id,
        status,
        case_id,
        referred_non_user_id,
        compensation_terms,
        acceptance_deadline,
        created_at,
        cases!inner (
          id,
          title,
          description,
          practice_area,
          status
        ),
        non_users!referrals_referred_non_user_id_fkey (
          id,
          first_name,
          last_name,
          company_name,
          job_title,
          email,
          phone
        )
      `)
      .eq('referring_user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Database query error:', error);
      throw error;
    }

    console.log('Raw referral data:', data);

    const formattedReferrals = data.map(ref => {
      console.log('Processing referral:', ref.id, {
        case_id: ref.case_id,
        non_user_id: ref.referred_non_user_id,
        name: ref.non_users?.company_name,
        contact: `${ref.non_users?.first_name || ''} ${ref.non_users?.last_name || ''}`.trim(),
        email: ref.non_users?.email,
        phone: ref.non_users?.phone
      });

      return {
        id: ref.id,
        logo: "/api/placeholder/40/40",
        // Contact info
        name: ref.non_users?.company_name || '',
        contact: `${ref.non_users?.first_name || ''} ${ref.non_users?.last_name || ''}`.trim(),
        email: ref.non_users?.email || '',
        phone: ref.non_users?.phone || '',
        position: ref.non_users?.job_title || 'Attorney',
        status: ref.status,
        progress: calculateProgress(ref.status),
        // Case details
        caseDetails: {
          title: ref.cases?.title || '',
          description: ref.cases?.description || '',
          practiceArea: ref.cases?.practice_area || ''
        },
        // Critical IDs
        case_id: ref.case_id,
        non_user_id: ref.referred_non_user_id,
        // Additional fields
        compensationTerms: ref.compensation_terms,
        acceptanceDeadline: ref.acceptance_deadline,
        files: []
      };
    });

    console.log('Formatted referrals:', formattedReferrals);
    return { success: true, data: formattedReferrals };
  } catch (error) {
    console.error('Error fetching referrals:', error);
    return { 
      success: false, 
      error: error.message || 'Failed to fetch referrals'
    };
  }
};

export const handleReferralSubmit = async (formData, files) => {
  try {
    const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;

    console.log('Creating new referral with data:', formData);
    
    const { data: nonUser, error: nonUserError } = await supabase
      .from('non_users')
      .insert([{
        id: crypto.randomUUID(),
        first_name: formData.name.split(' ')[0],
        last_name: formData.name.split(' ').slice(1).join(' '),
        email: formData.email,
        phone: formatPhoneNumber(formData.phone),
        company_name: formData.firm,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (nonUserError) {
      console.error('Error creating non-user:', nonUserError);
      throw nonUserError;
    }

    const { data: caseData, error: caseError } = await supabase
      .from('cases')
      .insert([{
        id: crypto.randomUUID(),
        title: `${formData.name} - ${formData.selectedSubject}`,
        description: formData.caseDetails,
        practice_area: formData.selectedSubject,
        status: 'pending',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (caseError) {
      console.error('Error creating case:', caseError);
      throw caseError;
    }

    const { error: referralError } = await supabase
      .from('referrals')
      .insert([{
        id: crypto.randomUUID(),
        case_id: caseData.id,
        referring_user_id: authUser.id,
        referred_non_user_id: nonUser.id,
        status: 'pending',
        email_sent: false,
        reminder_count: 0,
        acceptance_deadline: new Date(formData.acceptanceDeadline).toISOString(),
        compensation_terms: `${formData.compensationPercentage}% referral fee`,
        referral_type: 'standard',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);

    if (referralError) {
      console.error('Error creating referral:', referralError);
      throw referralError;
    }

    if (files?.length > 0) {
      for (const file of files) {
        const fileKey = `referrals/${caseData.id}/${file.name}`;
        const { error: uploadError } = await supabase.storage
          .from('documents')
          .upload(fileKey, file);

        if (uploadError) {
          console.error('Error uploading file:', uploadError);
          throw uploadError;
        }

        const { error: docError } = await supabase
          .from('documents')
          .insert([{
            id: crypto.randomUUID(),
            url_id: fileKey,
            created_by: authUser.id,
            case_id: caseData.id,
            file_name: file.name,
            file_type: file.type,
            file_size: file.size,
            is_a_lien: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }]);

        if (docError) {
          console.error('Error creating document record:', docError);
          throw docError;
        }
      }
    }

    const { data: currentUser } = await supabase
      .from('users')
      .select('total_referrals_made')
      .eq('id', authUser.id)
      .single();

    const { error: updateError } = await supabase
      .from('users')
      .update({
        total_referrals_made: (currentUser?.total_referrals_made || 0) + 1
      })
      .eq('id', authUser.id);

    if (updateError) {
      console.error('Error updating referral count:', updateError);
      throw updateError;
    }

    return { success: true };
  } catch (error) {
    console.error('Error in handleReferralSubmit:', error);
    return { 
      success: false, 
      error: error.message || 'An error occurred during submission' 
    };
  }
};

export const updateReferral = async (referralId, updateData) => {
  try {
    console.log('Updating referral:', { referralId, updateData });

    if (!updateData.non_user_id || !updateData.case_id) {
      throw new Error('Missing required IDs for update');
    }

    const { error: nonUserError } = await supabase
      .from('non_users')
      .update({
        first_name: updateData.name.split(' ')[0],
        last_name: updateData.name.split(' ').slice(1).join(' '),
        email: updateData.email,
        phone: formatPhoneNumber(updateData.phone),
        company_name: updateData.firm,
        updated_at: new Date().toISOString()
      })
      .eq('id', updateData.non_user_id);

    if (nonUserError) throw nonUserError;

    const { error: caseError } = await supabase
      .from('cases')
      .update({
        title: `${updateData.name} - ${updateData.selectedSubject}`,
        description: updateData.caseDetails,
        practice_area: updateData.selectedSubject,
        updated_at: new Date().toISOString()
      })
      .eq('id', updateData.case_id);

    if (caseError) throw caseError;

    const { data: referral, error: referralError } = await supabase
      .from('referrals')
      .update({
        compensation_terms: `${updateData.compensationPercentage}% referral fee`,
        acceptance_deadline: updateData.acceptanceDeadline,
        updated_at: new Date().toISOString()
      })
      .eq('id', referralId)
      .select()
      .single();

    if (referralError) throw referralError;

    return { success: true, data: referral };
  } catch (error) {
    console.error('Error updating referral:', error);
    return {
      success: false,
      error: error.message || 'Failed to update referral'
    };
  }
};

export const updateReferralStatus = async (referralId, newStatus) => {
  try {
    const { data, error } = await supabase
      .from('referrals')
      .update({ 
        status: newStatus,
        updated_at: new Date().toISOString()
      })
      .eq('id', referralId)
      .select('*')
      .single();

    if (error) throw error;
    return { success: true, data };
  } catch (error) {
    console.error('Error updating referral status:', error);
    return { 
      success: false, 
      error: error.message || 'Failed to update status'
    };
  }
};

export const deleteReferral = async (referralId) => {
  try {
    const { data: referral, error: referralError } = await supabase
      .from('referrals')
      .select('case_id')
      .eq('id', referralId)
      .single();

    if (referralError) throw referralError;

    const { data: documents, error: docsError } = await supabase
      .from('documents')
      .select('url_id')
      .eq('case_id', referral.case_id);

    if (docsError) throw docsError;

    for (const doc of documents || []) {
      await supabase.storage
        .from('documents')
        .remove([doc.url_id]);
    }

    await supabase
      .from('documents')
      .delete()
      .eq('case_id', referral.case_id);

    const { error: deleteError } = await supabase
      .from('referrals')
      .delete()
      .eq('id', referralId);

    if (deleteError) throw deleteError;

    await supabase
      .from('cases')
      .delete()
      .eq('id', referral.case_id);

    return { success: true };
  } catch (error) {
    console.error('Error deleting referral:', error);
    return { 
      success: false, 
      error: error.message || 'Failed to delete referral'
    };
  }
};