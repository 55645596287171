import React from 'react';
import { User } from 'lucide-react';

const UserAvatar = ({ src, alt = "User avatar", size = 40 }) => {
  if (!src) {
    return (
      <div 
        className={`rounded-full bg-[#1A1A1A] flex items-center justify-center`}
        style={{ width: size, height: size }}
      >
        <User 
          className="text-[#C5C0BB]" 
          size={size * 0.6}
        />
      </div>
    );
  }

  return (
    <img 
      src={src}
      alt={alt}
      className="rounded-full object-cover"
      style={{ width: size, height: size }}
    />
  );
};

export default UserAvatar;