import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, Upload, FileText, Loader } from 'lucide-react';

const templatesByType = {
  'Medical': [
    { id: 'med-1', name: 'Hospital Lien Form', fields: ['patientName', 'admissionDate', 'treatmentCost', 'hospitalName'] },
    { id: 'med-2', name: 'Medical Provider Claim', fields: ['providerName', 'serviceDate', 'claimAmount', 'diagnosis'] }
  ],
  'Property': [
    { id: 'prop-1', name: 'Mechanics Lien', fields: ['propertyAddress', 'workDescription', 'completionDate', 'contractAmount'] },
    { id: 'prop-2', name: 'Construction Lien', fields: ['contractorName', 'projectDescription', 'materialsCost', 'laborCost'] }
  ],
  'Tax': [
    { id: 'tax-1', name: 'Federal Tax Lien', fields: ['taxpayerName', 'taxPeriod', 'assessmentDate', 'unpaidAmount'] },
    { id: 'tax-2', name: 'State Tax Lien', fields: ['businessName', 'taxType', 'periodEnd', 'penaltyAmount'] }
  ]
};

const LienDocumentPage = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState({});
  const [uploading, setUploading] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSelectedTemplate(null);
    setFormData({});
  };

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
    const initialData = template.fields.reduce((acc, field) => ({
      ...acc,
      [field]: ''
    }), {});
    setFormData(initialData);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleGenerateAndUpload = async () => {
    setUploading(true);
    setStatusMessage(null);

    try {
      // Simulate PDF generation and upload
      //code for AWS S3 upload
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setStatusMessage({
        type: 'success',
        message: 'Document successfully generated and uploaded'
      });
      
      // Optionally navigate back after successful upload
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    } catch (error) {
      setStatusMessage({
        type: 'error',
        message: 'Failed to generate and upload document'
      });
    } finally {
      setUploading(false);
    }
  };

  const isFormComplete = selectedTemplate?.fields.every(field => formData[field]?.trim());

  return (
    <div className="min-h-screen bg-[#d8d1c7]">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/dashboard/liens')}
                className="text-[#3d3731] hover:text-black transition-colors duration-150 flex items-center mr-4"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <h1 className="text-2xl font-semibold text-black">Add Document</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-[#e9e4dc] rounded-lg shadow-sm border border-[#a39b8f] p-6">
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-black mb-4">Document Generation</h2>
            <p className="text-[#3d3731]">Generate and upload lien documents using our pre-defined templates.</p>
          </div>

          {/* Status Message */}
          {statusMessage && (
            <div className={`mb-6 p-4 rounded-md ${
              statusMessage.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
            }`}>
              {statusMessage.message}
            </div>
          )}

          {/* Type Selection */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-[#3d3731] mb-2">
              Select Lien Type
            </label>
            <select
              value={selectedType}
              onChange={handleTypeChange}
              className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
            >
              <option value="">Choose a type...</option>
              {Object.keys(templatesByType).map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          {/* Template Selection */}
          {selectedType && (
            <div className="mb-6">
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Select Template
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {templatesByType[selectedType].map(template => (
                  <button
                    key={template.id}
                    onClick={() => handleTemplateChange(template)}
                    className={`p-4 border rounded-md flex items-center ${
                      selectedTemplate?.id === template.id
                        ? 'border-black bg-[#d8d1c7]'
                        : 'border-[#a39b8f] hover:bg-[#d8d1c7]'
                    }`}
                  >
                    <FileText className="w-5 h-5 mr-2 text-[#3d3731]" />
                    <span className="text-[#3d3731]">{template.name}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Template Form */}
          {selectedTemplate && (
            <div className="mb-6">
              <h3 className="text-lg font-medium text-black mb-4">Fill Template Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {selectedTemplate.fields.map(field => (
                  <div key={field}>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">
                      {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                    </label>
                    <input
                      type="text"
                      value={formData[field] || ''}
                      onChange={(e) => handleInputChange(field, e.target.value)}
                      className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Actions */}
          {selectedTemplate && (
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => navigate('/dashboard/liens')}
                className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150"
              >
                Cancel
              </button>
              <button
                onClick={handleGenerateAndUpload}
                disabled={!isFormComplete || uploading}
                className={`flex items-center px-4 py-2 rounded-md ${
                  isFormComplete && !uploading
                    ? 'bg-black text-white hover:bg-[#3d3731]'
                    : 'bg-[#a39b8f] text-[#3d3731] cursor-not-allowed'
                } transition-colors duration-150`}
              >
                {uploading ? (
                  <>
                    <Loader className="w-4 h-4 mr-2 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    <Upload className="w-4 h-4 mr-2" />
                    Generate & Upload
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LienDocumentPage;