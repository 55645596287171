// components/signup/registration/FormFooter.js
import React from 'react';

export const FormFooter = ({ onLoginClick }) => (
  <>
    <div className="mt-6 text-center">
      <p className="text-sm tracking-wider text-[#1A1A1A]">
        HAVE AN ACCOUNT?{' '}
        <button 
          onClick={onLoginClick}
          className="underline hover:no-underline"
        >
          LOG IN
        </button>
      </p>
    </div>

    <div className="mt-8 flex justify-center gap-6">
      <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">
        Instagram
      </button>
      <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">
        LinkedIn
      </button>
      <button className="tracking-wider text-sm text-[#1A1A1A] hover:underline">
        Behance
      </button>
    </div>

    <div className="mt-4 text-center text-xs tracking-wider text-[#1A1A1A]">
      ©Lawdie 2024
    </div>
  </>
);