// components/signup/registration/FormHeader.js
import React from 'react';

export const FormHeader = ({ title, subtitle }) => (
  <>
    <div className="flex items-center gap-2 mb-8 mt-16">
      <div className="w-8 h-8 bg-[#1A1A1A] rounded-full flex items-center justify-center">
        <div className="w-4 h-4 border-2 border-[#C5C0BB] rounded-full"></div>
      </div>
      <span className="text-xl tracking-widest text-[#1A1A1A]">LAWDIE</span>
    </div>
    <h1 
      className="text-4xl font-bold mb-2 tracking-wider" 
      style={{
        WebkitTextStroke: '1px #1A1A1A',
        WebkitTextFillColor: 'transparent'
      }}
    >
      {title}
    </h1>
    <p className="text-[#1A1A1A] mb-8 tracking-wider">{subtitle}</p>
  </>
);

export default FormHeader;  // Add this line