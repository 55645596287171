// components/signup/registration/RegistrationPhases/PersonalDetails.js
import React from 'react';
import { FormInput } from '../FormInput';

export const PersonalDetails = ({ formData, onChange, validationErrors, isOAuthUser }) => {
  const handlePhoneChange = (e) => {
    // Only allow numeric input
    const numericValue = e.target.value.replace(/\D/g, '');
    
    // Format phone number as user types
    let formattedPhone = numericValue;
    if (numericValue.length > 0) {
      formattedPhone = numericValue.slice(0, 10); // Limit to 10 digits
    }

    onChange({
      target: {
        name: 'phone',
        value: formattedPhone
      }
    });
  };

  const validatePasswordRequirements = (password) => {
    const requirements = [];
    if (password.length < 8) {
      requirements.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(password)) {
      requirements.push("Include at least one uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
      requirements.push("Include at least one lowercase letter");
    }
    if (!/[0-9]/.test(password)) {
      requirements.push("Include at least one number");
    }
    return requirements;
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    const requirements = validatePasswordRequirements(value);
    
    onChange({
      target: {
        name: 'password',
        value: value
      }
    });

    if (requirements.length > 0) {
      onChange({
        target: {
          name: 'passwordErrors',
          value: requirements
        }
      });
    }
  };

  return (
    <div className="space-y-6">
      {/* Name Fields */}
      <div className="grid grid-cols-2 gap-6">
        <FormInput
          label="FIRST NAME"
          name="firstName"
          value={formData.firstName}
          onChange={onChange}
          error={validationErrors.firstName}
          disabled={isOAuthUser}
          required
          placeholder="Enter your first name"
        />
        <FormInput
          label="LAST NAME"
          name="lastName"
          value={formData.lastName}
          onChange={onChange}
          error={validationErrors.lastName}
          disabled={isOAuthUser}
          required
          placeholder="Enter your last name"
        />
      </div>

      {/* Contact Fields */}
      <div className="grid grid-cols-2 gap-6">
        <FormInput
          label="EMAIL"
          name="email"
          type="email"
          value={formData.email}
          onChange={onChange}
          error={validationErrors.email}
          disabled={isOAuthUser}
          required
          placeholder="Enter your email"
        />
        <FormInput
          label="PHONE"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handlePhoneChange}
          error={validationErrors.phone}
          placeholder="10-digit number"
          maxLength={10}
          required
        />
      </div>

      {/* Only show these fields if not OAuth user */}
      {!isOAuthUser && (
        <>
          <FormInput
            label="DATE OF BIRTH"
            name="dateOfBirth"
            type="date"
            value={formData.dateOfBirth}
            onChange={onChange}
            error={validationErrors.dateOfBirth}
            required
          />

          <div className="grid grid-cols-2 gap-6">
            <div>
              <FormInput
                label="PASSWORD"
                name="password"
                type="password"
                value={formData.password}
                onChange={handlePasswordChange}
                error={validationErrors.password}
                required
                placeholder="Enter password"
              />
              {formData.passwordErrors && (
                <ul className="mt-2 text-xs text-red-500">
                  {formData.passwordErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </div>
            <FormInput
              label="CONFIRM PASSWORD"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={onChange}
              error={validationErrors.confirmPassword}
              required
              placeholder="Confirm password"
            />
          </div>
        </>
      )}

      {/* Terms Agreement */}
      <div className="flex items-center gap-2 text-[#1A1A1A]">
        <input
          type="checkbox"
          name="agreeToTerms"
          checked={formData.agreeToTerms}
          onChange={onChange}
          className="w-4 h-4 border-[#1A1A1A] rounded-none focus:ring-[#1A1A1A]"
        />
        <span className="text-sm tracking-wider">
          I AGREE TO ALL THE{' '}
          <button type="button" className="underline hover:no-underline">
            TERMS
          </button>
          {' '}AND{' '}
          <button type="button" className="underline hover:no-underline">
            PRIVACY POLICY
          </button>
        </span>
      </div>
    </div>
  );
};

export default PersonalDetails;

console.log('PersonalDetails loaded');
