import React, { useState, useRef } from 'react';
import { Upload, FileText, Check, Calendar, X, DollarSign } from 'lucide-react';

export const FilesStep = ({ 
  phase, 
  files, 
  onFileUpload, 
  compensationPercentage, 
  acceptanceDeadline,
  onCompensationChange,
  onDeadlineChange 
}) => {
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    console.log('Files selected:', e.target.files);
    const selectedFiles = Array.from(e.target.files);
    onFileUpload([...files, ...selectedFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    console.log('Files dropped');
    const droppedFiles = Array.from(e.dataTransfer.files);
    onFileUpload([...files, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    console.log('Removing file at index:', index);
    const newFiles = files.filter((_, i) => i !== index);
    onFileUpload(newFiles);
  };

  if (phase === 1) {
    return (
      <div>
        <h2 className="text-xl font-semibold text-black mb-2">
          Anything to add?
        </h2>
        <p className="text-[#3d3731] mb-6">
          Please fill the form below to submit a referral. Feel free to add as much detail as needed.
        </p>

        <div>
          <h3 className="text-lg font-medium text-black mb-2">
            Any relevant files or attachments
          </h3>
          <p className="text-[#3d3731] mb-6">
            Please attach any documents as wanted.
          </p>

          <div 
            onClick={() => fileInputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="border-2 border-dashed border-[#d8d1c7] rounded-lg p-12 text-center bg-[#f5f1eb] hover:border-[#a39b8f] transition-colors duration-150 cursor-pointer"
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              className="hidden"
              multiple
              accept=".jpeg,.jpg,.png,.gif,.mp4,.pdf,.psd,.ai,.doc,.docx,.ppt,.pptx"
            />
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 mb-4">
                <Upload className="w-full h-full text-[#a39b8f]" />
              </div>
              <p className="text-[#3d3731] mb-2">
                Drag & drop files or{' '}
                <span className="text-black underline">Browse</span>
              </p>
              <p className="text-sm text-[#a39b8f]">
                Supported formats: JPEG, PNG, GIF, MP4, PDF, DOC, DOCX, PPT
              </p>
            </div>
          </div>
        </div>

        {files && files.length > 0 && (
          <div className="mt-6 space-y-2">
            {files.map((file, index) => (
              <div key={index} className="flex items-center justify-between p-3 bg-white border border-[#d8d1c7] rounded-lg">
                <div className="flex items-center gap-2">
                  <span className="text-[#3d3731]">{file.name}</span>
                  <span className="text-[#a39b8f] text-sm">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </span>
                </div>
                <button 
                  onClick={() => handleRemoveFile(index)}
                  className="text-[#a39b8f] hover:text-black"
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-semibold text-black mb-2">
        Terms and Deadline
      </h2>
      <p className="text-[#3d3731] mb-6">
        Set the compensation terms and acceptance deadline for this referral.
      </p>

      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <label className="text-lg font-medium text-black">
            Compensation Percentage
          </label>
          <div className="flex items-center text-[#3d3731]">
            <DollarSign className="w-4 h-4 mr-1" />
            <span>{compensationPercentage}%</span>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={compensationPercentage}
          onChange={(e) => onCompensationChange(parseInt(e.target.value))}
          className="w-full h-2 bg-[#d8d1c7] rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm text-[#a39b8f] mt-1">
          <span>0%</span>
          <span>50%</span>
          <span>100%</span>
        </div>
      </div>

      <div>
        <label className="text-lg font-medium text-black mb-2 block">
          Acceptance Deadline
        </label>
        <div className="flex items-center space-x-4">
          <div className="relative flex-1">
            <Calendar className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#a39b8f] w-5 h-5" />
            <input
              type="date"
              value={acceptanceDeadline || ''}
              min={new Date().toISOString().split('T')[0]}
              onChange={(e) => onDeadlineChange(e.target.value)}
              className="w-full pl-12 pr-4 py-3 border border-[#d8d1c7] rounded-lg bg-[#f5f1eb] text-[#3d3731] focus:outline-none focus:border-black"
            />
          </div>
          <button
            onClick={() => {
              const date = new Date();
              date.setDate(date.getDate() + 14);
              onDeadlineChange(date.toISOString().split('T')[0]);
            }}
            className="px-4 py-2 text-sm text-[#3d3731] hover:text-black"
          >
            Reset to 2 weeks
          </button>
        </div>
      </div>
    </div>
  );
};

export const SubmitStep = ({ formData, files, onSubmit, onBack }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  const handleSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      setError(null);
      
      await onSubmit();
      
      setIsComplete(true);
    } catch (err) {
      console.error('Submission failed:', err);
      setError(err.message || 'Submission failed');
    } finally {
      setIsSubmitting(false);
    }
  };

  const reviewSections = [
    {
      title: 'Contact Information',
      fields: [
        { label: 'Name', value: formData.name },
        { label: 'Email', value: formData.email },
        { label: 'Phone', value: formData.phone },
        { label: 'Firm', value: formData.firm }
      ]
    },
    {
      title: 'Case Information',
      fields: [
        { label: 'Subject', value: formData.selectedSubject },
        { label: 'Details', value: formData.caseDetails }
      ]
    },
    {
      title: 'Terms',
      fields: [
        { label: 'Compensation', value: `${formData.compensationPercentage}%` },
        { 
          label: 'Deadline', 
          value: new Date(formData.acceptanceDeadline).toLocaleDateString() 
        }
      ]
    }
  ];

  return (
    <div className="flex flex-col items-center py-8">
      <div className="relative w-24 h-24 mb-8">
        <div className={`w-24 h-24 rounded-full flex items-center justify-center transition-all duration-500 ${
          error ? 'bg-red-500' : isComplete ? 'bg-black scale-110' : 'bg-black'
        }`}>
          <div className="relative">
            {!error ? (
              <>
                <FileText 
                  className={`w-12 h-12 text-[#e9e4dc] absolute top-0 left-0 transition-all duration-500 ${
                    isComplete ? 'opacity-0 scale-75' : 'opacity-100'
                  }`}
                />
                <Check 
                  className={`w-12 h-12 text-[#e9e4dc] absolute top-0 left-0 transition-all duration-500 ${
                    isComplete ? 'opacity-100 scale-100' : 'opacity-0 scale-150'
                  }`}
                />
              </>
            ) : (
              <X className="w-12 h-12 text-white" />
            )}
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-black mb-4">
        {error ? 'Submission Failed' : isComplete ? 'Submitted!' : 'Review & Submit'}
      </h2>

      {error ? (
        <div className="text-center mb-8">
          <p className="text-red-500 mb-4">{error}</p>
          <p className="text-[#3d3731]">Please try again or contact support if the problem persists.</p>
        </div>
      ) : (
        <div className="w-full max-w-2xl mb-8">
          {reviewSections.map((section, index) => (
            <div key={index} className="mb-6">
              <h3 className="font-medium text-lg mb-3">{section.title}</h3>
              <div className="bg-[#f5f1eb] rounded-lg p-4">
                {section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="flex justify-between py-2">
                    <span className="text-[#3d3731]">{field.label}:</span>
                    <span className="text-black font-medium">{field.value}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {files.length > 0 && (
            <div className="mb-6">
              <h3 className="font-medium text-lg mb-3">Attachments</h3>
              <div className="bg-[#f5f1eb] rounded-lg p-4">
                <div className="flex justify-between py-2">
                  <span className="text-[#3d3731]">Files:</span>
                  <span className="text-black font-medium">{files.length} file(s) attached</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex space-x-4">
        {!isComplete && (
          <button
            onClick={onBack}
            className="px-6 py-3 border border-[#d8d1c7] rounded-full text-[#3d3731] hover:border-[#a39b8f]"
            disabled={isSubmitting}
          >
            Back
          </button>
        )}
        
        <button
          onClick={handleSubmitClick}
          disabled={isSubmitting || isComplete}
          className={`px-8 py-3 rounded-full transition-all duration-300 ${
            error ? 'bg-red-500 hover:bg-red-600 text-white' :
            isComplete ? 'bg-[#3d3731] text-[#e9e4dc] opacity-75 cursor-not-allowed' :
            isSubmitting ? 'bg-[#3d3731] text-[#e9e4dc] cursor-wait' :
            'bg-black text-[#e9e4dc] hover:bg-[#3d3731]'
          }`}
        >
          {isSubmitting ? 'Submitting...' : 
           error ? 'Try Again' : 
           isComplete ? 'Complete!' : 
           'Submit Referral'}
        </button>
      </div>
    </div>
  );
};