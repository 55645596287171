// userService.js
import { supabase } from './supabase';

/**
 * Comprehensive user creation service that handles both users table
 * and lawyer_profiles for attorneys
 */
export const createUser = async (authUser, additionalData = {}) => {
  const userRole = localStorage.getItem('userRole') || 'client';
  
  try {
    // Start a Supabase transaction
    const { data: userData, error: userError } = await supabase
      .from('users')
      .insert([{
        first_name: authUser.user_metadata?.given_name || additionalData.firstName || '',
        last_name: authUser.user_metadata?.family_name || additionalData.lastName || '',
        middle_name: additionalData.middleName || '',
        email: authUser.email,
        phone: additionalData.phone || '',
        street_address: additionalData.streetAddress || '',
        city: additionalData.city || '',
        state: additionalData.state || '',
        zip_code: additionalData.zipCode || '',
        user_type: userRole,
        is_public: additionalData.isPublic || false,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (userError) throw userError;

    // If user is an attorney, create lawyer profile
    if (userRole === 'attorney') {
      const { error: lawyerError } = await supabase
        .from('lawyer_profiles')
        .insert([{
          user_id: userData.id,
          firm_name: additionalData.firmName || '',
          practice_areas: additionalData.practiceAreas || [],
          bar_number: additionalData.barId || '',
          years_of_experience: additionalData.yearsOfExperience || 0,
          bio: additionalData.bio || '',
          website: additionalData.website || '',
          office_address: additionalData.officeAddress || '',
          referral_count: 0,
          is_visible: additionalData.isVisible || true,
          verification_status: 'pending',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);

      if (lawyerError) {
        // Rollback user creation if lawyer profile creation fails
        await supabase
          .from('users')
          .delete()
          .match({ id: userData.id });
        
        throw lawyerError;
      }
    }

    return userData;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

/**
 * Check if user exists in our database
 */
export const checkExistingUser = async (email) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select(`
        *,
        lawyer_profiles (*)
      `)
      .eq('email', email)
      .single();
    
    if (error && error.code !== 'PGRST116') throw error;
    return data;
  } catch (error) {
    console.error('Error checking existing user:', error);
    throw error;
  }
};

/**
 * Handle OAuth callback and determine next steps
 */
export const handleOAuthCallback = async (session) => {
  try {
    const { user } = session;
    
    // Check if user exists in our database
    const existingUser = await checkExistingUser(user.email);
    if (existingUser) {
      return {
        action: 'REDIRECT',
        destination: '/dashboard',
        user: existingUser
      };
    }

    // Get selected role
    const userRole = localStorage.getItem('userRole');
    
    // For attorneys, we need additional information
    if (userRole === 'attorney') {
      return {
        action: 'COMPLETE_PROFILE',
        destination: '/signup/complete-profile',
        authUser: user
      };
    }

    // For clients, create basic profile
    const newUser = await createUser(user);
    return {
      action: 'REDIRECT',
      destination: '/dashboard',
      user: newUser
    };
  } catch (error) {
    console.error('Error in OAuth callback:', error);
    return {
      action: 'ERROR',
      error: error.message
    };
  }
};

/**
 * Complete attorney profile with all required information
 */
export const completeAttorneyProfile = async (authUser, profileData) => {
  try {
    const user = await createUser(authUser, {
      ...profileData,
      isPublic: true,
      isVisible: true
    });

    return user;
  } catch (error) {
    console.error('Error completing attorney profile:', error);
    throw error;
  }
};