import React from 'react';

const WhyChooseUs = ({ isBlackAndWhite }) => {
  const textColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#a39b8f]';
  const buttonColor = isBlackAndWhite 
    ? 'bg-gray-800 text-white hover:bg-gray-700' 
    : 'bg-[#2a2118] text-[#d8d1c7] hover:bg-[#3d3326]';
  const backgroundColor = isBlackAndWhite ? 'bg-white' : 'bg-[#0f0b08]';
  const headingColor = isBlackAndWhite 
    ? 'text-gray-800' 
    : 'text-[#d8d1c7]';

  return (
    <section className={`py-16 ${backgroundColor}`}>
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img src="/api/placeholder/400/300" alt="Why choose us illustration" className="w-full h-auto rounded-lg shadow-md" />
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h2 className={`text-3xl font-bold ${headingColor} mb-6`}>Why Choose Us</h2>
          <p className={`${textColor} mb-6`}>
            Our dedicated team understands the critical need for modern efficiencies in the legal industry. We're committed to delivering cutting-edge solutions that streamline your workflow, enhance client relationships, and ultimately empower your firm to excel in a competitive landscape.
          </p>
          <button className={`${buttonColor} font-bold py-2 px-6 rounded-full transition duration-300`}>
            Let's Connect
          </button>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;