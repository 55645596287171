import React from 'react';
import { ClipboardList, Users, Globe, FileText } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, comingSoon, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-white border border-gray-200 shadow-sm'
    : 'bg-[#1a1410] border border-[#2a2118]';

  const titleColor = isBlackAndWhite ? 'text-gray-800' : 'text-[#d8d1c7]';
  const iconColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#a39b8f]';
  const descriptionColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#8a8178]';
  const comingSoonStyle = isBlackAndWhite
    ? 'bg-gray-100 text-gray-600'
    : 'bg-[#2a2118] text-[#a39b8f]';

  return (
    <div className={`p-6 rounded-lg ${cardStyle}`}>
      <div className="flex items-center mb-4">
        <Icon className={`w-6 h-6 mr-2 ${iconColor}`} />
        <h3 className={`text-lg font-semibold ${titleColor}`}>{title}</h3>
        {comingSoon && (
          <span className={`ml-2 text-xs px-2 py-1 rounded-full ${comingSoonStyle}`}>
            Coming Soon!
          </span>
        )}
      </div>
      <p className={descriptionColor}>{description}</p>
    </div>
  );
};

const ServicesOffered = ({ isBlackAndWhite = false }) => {
  const services = [
    {
      icon: ClipboardList,
      title: "Referral Management/Network",
      description: "Efficiently track and manage referrals while expanding your professional network to grow your practice.",
      comingSoon: false
    },
    {
      icon: FileText,
      title: "Case Management",
      description: "Stay organized and efficient with our comprehensive case management tools. Manage deadlines, tasks, documents, and communications.",
      comingSoon: true
    },
    {
      icon: Users,
      title: "Client Relationship Management",
      description: "Build stronger client relationships with our intuitive platform, enabling personalized interactions, follow-ups, and insights to enhance client satisfaction.",
      comingSoon: true
    },
    {
      icon: Globe,
      title: "Online Presence",
      description: "Boost your visibility and attract new clients with our integrated online presence tools. Manage your digital footprint effortlessly.",
      comingSoon: true
    }
  ];

  const containerStyle = isBlackAndWhite
    ? 'bg-white'
    : 'bg-[#0f0b08]';

  const headingStyle = isBlackAndWhite
    ? 'text-gray-800'
    : 'text-[#d8d1c7]';

  return (
    <section className={`py-16 ${containerStyle}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-3xl font-bold mb-8 text-center ${headingStyle}`}>
          We Offer
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <ServiceCard 
              key={index} 
              {...service} 
              isBlackAndWhite={isBlackAndWhite} 
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOffered;