import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
  const navigate = useNavigate();

  const handleRoleSelect = (role) => {
    console.log('Setting role:', role); // Add this log
    localStorage.setItem('userRole', role);
    navigate('/signup/login');
  };

  // Minimal role button component with lawdie-2's styling
  const RoleButton = ({ role, onClick }) => (
    <div className="flex flex-col items-center">
      <button 
        onClick={onClick}
        className="w-64 h-64 mb-6 border border-[#1A1A1A] bg-[#1A1A1A] text-[#C5C0BB] hover:bg-transparent hover:text-[#1A1A1A] transition-colors duration-300 flex flex-col items-center justify-center"
      >
        <span className="text-2xl tracking-wider" style={{
          WebkitTextStroke: '1px currentColor',
          WebkitTextFillColor: 'transparent'
        }}>{role.toUpperCase()}</span>
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#C5C0BB] flex flex-col">
      {/* Top Navigation */}
      <div className="fixed top-0 left-0 right-0 flex items-center p-6 text-[#1A1A1A] bg-[#C5C0BB]">
        <button 
          onClick={() => navigate(-1)}
          className="text-sm font-light hover:text-[#2A2A2A] flex items-center gap-2 tracking-wider"
        >
          ← BACK
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center pt-32 px-6">
        {/* Logo */}
        <div className="flex items-center gap-2 mb-16">
          <div className="w-8 h-8 bg-[#1A1A1A] rounded-full flex items-center justify-center">
            <div className="w-4 h-4 border-2 border-[#C5C0BB] rounded-full"></div>
          </div>
          <span className="text-xl tracking-widest text-[#1A1A1A]">LAWDIE</span>
        </div>

        {/* Title */}
        <h1 className="text-4xl font-bold mb-16 tracking-wider" style={{
          WebkitTextStroke: '1px #1A1A1A',
          WebkitTextFillColor: 'transparent'
        }}>WHO ARE YOU</h1>

        {/* Role Selection */}
        <div className="flex justify-center items-center gap-16">
          <RoleButton role="Client" onClick={() => handleRoleSelect('client')} />
          
          <div className="text-[#1A1A1A] tracking-wider">
            OR
          </div>
          
          <RoleButton role="Attorney" onClick={() => handleRoleSelect('attorney')} />
        </div>

        {/* Footer */}
        <div className="mt-auto py-12 text-center">
          <div className="text-[#1A1A1A] tracking-wider text-sm mb-2">
            LAWDIE
          </div>
          <div className="text-[#1A1A1A] tracking-wider text-xs">
            FOR CLIENTS, ATTORNEYS, EVERYONE.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;