import React, { useState, useEffect, useRef } from 'react';
import { CustomerRow } from './CustomerRow';
import { ChevronDown, ChevronUp, GripHorizontal, AlertCircle } from 'lucide-react';
import { Resizable } from 're-resizable';
import { updateReferralStatus, calculateProgress } from '../../backend/referralService';

export const CustomerTable = ({ customers: initialCustomers, onRefresh }) => {
  const [customers, setCustomers] = useState(initialCustomers);
  const [selectedCustomers, setSelectedCustomers] = useState(new Set());
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [height, setHeight] = useState(400);
  const [isResizing, setIsResizing] = useState(false);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  
  // Pagination settings
  const itemsPerPage = expanded ? 10 : 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  // Filter and sort customers
  const filteredCustomers = customers
    .filter(customer => {
      if (!searchQuery) return true;
      const searchLower = searchQuery.toLowerCase();
      return (
        customer.name.toLowerCase().includes(searchLower) ||
        customer.contact.toLowerCase().includes(searchLower) ||
        customer.status.toLowerCase().includes(searchLower)
      );
    });

  // Sort customers if sort config is set
  const sortedCustomers = [...filteredCustomers].sort((a, b) => {
    if (!sortConfig.key) return 0;
    
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const currentCustomers = sortedCustomers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedCustomers.length / itemsPerPage);

  // Calculate heights for resizing
  const rowHeight = 73;
  const headerHeight = 48;
  const footerHeight = 57;
  const actualRowCount = currentCustomers.length;
  const minTableHeight = headerHeight + (rowHeight * actualRowCount) + footerHeight;

  useEffect(() => {
    setCustomers(initialCustomers);
  }, [initialCustomers]);

  useEffect(() => {
    setHeight(minTableHeight);
  }, [expanded, currentPage, minTableHeight]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleStatusUpdate = async (referralId, newStatus) => {
    setError(null);
    const previousCustomers = customers;

    // Optimistic update
    setCustomers(prevCustomers => 
      prevCustomers.map(customer => 
        customer.id === referralId 
          ? {
              ...customer,
              status: newStatus,
              progress: calculateProgress(newStatus)
            }
          : customer
      )
    );

    try {
      const result = await updateReferralStatus(referralId, newStatus);
      if (!result.success) {
        throw new Error(result.error || 'Failed to update status');
      }
    } catch (error) {
      // Revert on error
      setCustomers(previousCustomers);
      setError(`Failed to update status: ${error.message}`);
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const newSelected = new Set(currentCustomers.map(c => c.id));
      setSelectedCustomers(newSelected);
    } else {
      setSelectedCustomers(new Set());
    }
  };

  const handleSelectCustomer = (id, checked) => {
    const newSelected = new Set(selectedCustomers);
    if (checked) {
      newSelected.add(id);
    } else {
      newSelected.delete(id);
    }
    setSelectedCustomers(newSelected);
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <div className="flex flex-col">
      {/* Error Message */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-center text-red-700">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      <div className="flex flex-col border border-[#d8d1c7] rounded-lg bg-[#f5f1eb]">
        <Resizable
          size={{ width: '100%', height }}
          minHeight={minTableHeight}
          maxHeight={800}
          enable={{ 
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          handleComponent={{
            bottom: (
              <div className="flex items-center justify-center h-4 bg-[#d8d1c7] cursor-ns-resize w-full hover:bg-[#c5beb2] transition-colors">
                <GripHorizontal size={16} className="text-[#3d3731]" />
              </div>
            )
          }}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={(e, direction, ref, d) => {
            const newHeight = height + d.height;
            setHeight(newHeight);
            setExpanded(newHeight > minTableHeight);
            setIsResizing(false);
          }}
        >
          <div className="h-full flex flex-col">
            <div className="overflow-auto flex-1">
              <table className="w-full divide-y divide-[#d8d1c7]">
                <thead className="bg-[#d8d1c7] sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider w-10">
                      <input 
                        type="checkbox" 
                        checked={currentCustomers.length > 0 && currentCustomers.every(c => selectedCustomers.has(c.id))}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" 
                      />
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('name')}
                    >
                      Firm
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('status')}
                    >
                      Status
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('contact')}
                    >
                      Contact
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('progress')}
                    >
                      Progress
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-[#3d3731] uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#d8d1c7] bg-white">
                  {currentCustomers.map((customer) => (
                    <CustomerRow 
                      key={customer.id} 
                      {...customer} 
                      onStatusUpdate={handleStatusUpdate}
                      onRefresh={onRefresh}
                      selected={selectedCustomers.has(customer.id)}
                      onSelect={handleSelectCustomer}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Resizable>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-[#d8d1c7] flex items-center justify-between bg-[#f5f1eb]">
          <div className="text-sm text-[#3d3731]">
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, sortedCustomers.length)} of {sortedCustomers.length} results
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex space-x-2">
              <button 
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 border border-[#a39b8f] rounded text-[#3d3731] bg-[#f5f1eb] hover:bg-[#d8d1c7] disabled:opacity-50"
              >
                Previous
              </button>
              <button 
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 border border-[#a39b8f] rounded text-[#3d3731] bg-[#f5f1eb] hover:bg-[#d8d1c7] disabled:opacity-50"
              >
                Next
              </button>
            </div>
            <button
              onClick={() => {
                setExpanded(!expanded);
                const newRowCount = !expanded ? Math.min(10, customers.length) : Math.min(4, customers.length);
                setHeight(headerHeight + (rowHeight * newRowCount) + footerHeight);
              }}
              className="flex items-center text-[#3d3731] hover:text-[#a39b8f]"
            >
              {expanded ? (
                <>
                  <ChevronUp size={20} />
                  <span className="ml-1">Show Less</span>
                </>
              ) : (
                <>
                  <ChevronDown size={20} />
                  <span className="ml-1">Show More</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;