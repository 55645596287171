// components/dashboard/CustomerListItem.js
import React from 'react';
import { Trash2, Edit2 } from 'lucide-react';

export const CustomerListItem = ({ logo, name, website, status, contact, position, progress }) => (
  <div className="p-4 border-b border-[#d8d1c7] flex items-center justify-between">
    <div className="flex items-center space-x-4">
      <input type="checkbox" className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" />
      <img className="h-10 w-10 rounded-full" src={logo} alt="" />
      <div>
        <div className="text-sm font-medium text-[#3d3731]">{name}</div>
        <div className="text-sm text-[#a39b8f]">{website}</div>
      </div>
    </div>
    <div className="flex items-center space-x-8">
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
        status === 'Customer' ? 'bg-[#c5beb2] text-[#3d3731]' : 'bg-[#a39b8f] text-[#e9e4dc]'
      }`}>
        {status}
      </span>
      <div>
        <div className="text-sm text-[#3d3731]">{contact}</div>
        <div className="text-sm text-[#a39b8f]">{position}</div>
      </div>
      <div className="w-32">
        <div className="w-full bg-[#d8d1c7] rounded-full h-2.5">
          <div className="bg-[#3d3731] h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      <div className="flex space-x-2">
        <button className="text-[#a39b8f] hover:text-[#3d3731]">
          <Trash2 size={20} />
        </button>
        <button className="text-[#a39b8f] hover:text-[#3d3731]">
          <Edit2 size={20} />
        </button>
      </div>
    </div>
  </div>
);
