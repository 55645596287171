// components/signup/registration/SocialSignIn.js
import React from 'react';

export const SocialSignIn = ({ 
  onGoogleClick, 
  onLinkedInClick, 
  isAttorney, 
  loading 
}) => (
  <>
    {isAttorney && (
      <button
        type="button"
        onClick={onLinkedInClick}
        disabled={loading}
        className="w-full px-6 py-3 bg-[#1A1A1A] text-[#C5C0BB] tracking-wider hover:bg-[#2A2A2A] disabled:opacity-50"
      >
        {loading ? 'PROCESSING...' : 'SIGN UP WITH LINKEDIN'}
      </button>
    )}
    <button
      type="button"
      onClick={onGoogleClick}
      disabled={loading}
      className="w-full px-6 py-3 bg-[#1A1A1A] text-[#C5C0BB] tracking-wider hover:bg-[#2A2A2A] disabled:opacity-50"
    >
      {loading ? 'PROCESSING...' : 'SIGN UP WITH GOOGLE'}
    </button>
  </>
);