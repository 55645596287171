// components/signup/registration/RegistrationPhases/FirmDetails.js
import React from 'react';
import { FormInput } from '../FormInput';
import { PracticeAreaSelector } from '../PracticeAreaSelector';

export const FirmDetails = ({ formData, onChange, validationErrors, isOAuthUser }) => (
  <div className="space-y-6">
    {isOAuthUser && (
      <FormInput
        label="PHONE"
        name="phone"
        type="tel"
        value={formData.phone}
        onChange={onChange}
        error={validationErrors.phone}
        required
      />
    )}

    <FormInput
      label="FIRM NAME"
      name="firmName"
      value={formData.firmName}
      onChange={onChange}
      error={validationErrors.firmName}
      required
    />

    <FormInput
      label="FIRM ADDRESS"
      name="office_address"  // Changed from firmAddress to office_address
      value={formData.office_address}  // Changed from formData.firmAddress
      onChange={onChange}
      error={validationErrors.office_address}
      placeholder="Street Address"
      required
    />

    <div className="grid grid-cols-3 gap-6">
      <FormInput
        label="CITY"
        name="firmCity"
        value={formData.firmCity}
        onChange={onChange}
        error={validationErrors.firmCity}
        required
      />
      <FormInput
        label="STATE"
        name="firmState"
        value={formData.firmState}
        onChange={onChange}
        error={validationErrors.firmState}
        required
      />
      <FormInput
        label="ZIP CODE"
        name="firmZip"
        value={formData.firmZip}
        onChange={onChange}
        error={validationErrors.firmZip}
        required
      />
    </div>

    <PracticeAreaSelector
      selectedAreas={formData.practiceAreas}
      onChange={(areas) => onChange({
        target: { name: 'practiceAreas', value: areas }
      })}
    />
  </div>
);

export default FirmDetails;

console.log('FirmDetails loaded');
