// FormInput.js (shared component)
import React from 'react';

const FormInput = ({ 
  icon: Icon, 
  label, 
  type = "text",
  value,
  onChange,
  onBlur,
  error,
  required = false,
  name,
  placeholder
}) => (
  <div>
    <label className="block text-sm font-medium text-[#3d3731] mb-2">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
          <Icon className={`h-5 w-5 ${error ? 'text-red-500' : 'text-[#a39b8f]'}`} />
        </div>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={`w-full h-11 ${Icon ? 'pl-12' : 'pl-4'} pr-4 border ${
          error 
            ? 'border-red-500 focus:ring-red-500 focus:border-red-500' 
            : 'border-[#d8d1c7] focus:ring-black focus:border-black'
        } rounded-full bg-[#f5f1eb] placeholder-[#a39b8f] text-[#3d3731] focus:outline-none focus:ring-1 transition-colors duration-150`}
        placeholder={placeholder}
      />
      {error && (
        <p className="absolute -bottom-5 left-0 text-xs text-red-500">
          {error}
        </p>
      )}
    </div>
  </div>
);

export default FormInput;