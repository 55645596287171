import React, { useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// Assuming you have a logo.png in your public/assets directory
// You should add your actual logo path here
const LOGO_PATH = '/assets/lawdie-logo.png';

const LandingPage = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  const handleSignUp = () => {
    navigate('/signup'); // Navigate to signup page
  };

  const navigateToCompanyPage = () => {
    navigate('/company');
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const drawGrid = () => {
      const gridSize = 80;
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(30, 30, 30, 0.8)';
      ctx.lineWidth = 1;

      for (let x = 0; x <= canvas.width; x += gridSize) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
      }

      for (let y = 0; y <= canvas.height; y += gridSize) {
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
      }

      ctx.stroke();
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawGrid();
      animationFrameId = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="min-h-screen bg-[#090909] text-white">
      <div className="relative min-h-screen overflow-hidden">
        <canvas ref={canvasRef} className="fixed inset-0 pointer-events-none" style={{ opacity: 0.5 }} />
        
        {/* Gradient overlays */}
        <div className="fixed inset-0">
          <div 
            className="absolute w-[600px] h-[600px]"
            style={{
              background: 'radial-gradient(circle at center, rgba(255, 140, 50, 0.3) 0%, rgba(255, 140, 50, 0) 70%)',
              bottom: '-200px',
              left: '-100px',
              filter: 'blur(80px)',
            }}
          />
          <div 
            className="absolute w-[600px] h-[600px]"
            style={{
              background: 'radial-gradient(circle at center, rgba(123, 97, 255, 0.3) 0%, rgba(123, 97, 255, 0) 70%)',
              top: '-200px',
              right: '-100px',
              filter: 'blur(80px)',
            }}
          />
        </div>

        {/* Content */}
        <div className="relative z-10">
          {/* Header */}
          <header className="p-6 flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img 
                src={LOGO_PATH} 
                alt="Lawdie Logo" 
                className="w-8 h-8"
                onError={(e) => {
                  // Fallback if image fails to load
                  e.target.style.display = 'none';
                  e.target.nextElementSibling.style.display = 'block';
                }}
              />
              {/* Fallback SVG logo - hidden by default */}
              <svg className="w-8 h-8 hidden" viewBox="0 0 32 32">
                <path fill="#FF9500" d="M16 0L32 8V24L16 32L0 24V8L16 0Z"/>
                <path fill="#FF4500" d="M16 4L28 10V22L16 28L4 22V10L16 4Z"/>
              </svg>
              <span className="text-lg font-medium">Lawdie</span>
            </Link>

            <nav>
              <ul className="flex items-center space-x-8">
                <li>
                  <button className="text-sm hover:text-gray-300" onClick={navigateToCompanyPage}>Company</button>
                </li>
                <li>
                  <button className="text-sm hover:text-gray-300">Services</button>
                </li>
                <li>
                  <button className="text-sm hover:text-gray-300">
                    Lawyer Search <span className="text-[#7B61FF]">(BETA)</span>
                  </button>
                </li>
                <li>
                  <Link 
                    to="/signup"
                    className="text-sm px-6 py-2 rounded-full border border-[#FF9500] text-[#FF9500] hover:bg-[#FF9500] hover:text-black transition-all duration-300"
                  >
                    Sign up!
                  </Link>
                </li>
              </ul>
            </nav>
          </header>

          {/* Hero Content */}
          <div className="flex flex-col items-center justify-center min-h-[calc(100vh-200px)]">
            <h1 className="text-8xl font-bold mb-16 tracking-tight">
              <span className="text-white">Coming </span>
              <span className="text-[#7B61FF]">Soon </span>
              <span className="text-[#7B61FF] opacity-60">...</span>
            </h1>
            
            <div className="flex w-full max-w-3xl space-x-4 px-4">
              <input
                type="email"
                placeholder="Email"
                className="w-full px-8 py-4 rounded-full bg-[#111111] border border-[#222222] focus:outline-none focus:border-[#7B61FF] placeholder-gray-500 text-lg"
              />
              <button className="px-8 py-4 text-black rounded-full hover:bg-[#FF8500] transition font-medium whitespace-nowrap text-lg bg-[#FF9500]">
                JOIN NEWSLETTER
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* We Offer Section */}
      <section className="py-24 bg-black">
        <div className="max-w-6xl mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-4">We Offer</h2>
          <p className="text-center text-gray-400 mb-12">
            The legal search process doesn't need to be complicated. Let us help!
          </p>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="p-8 rounded-lg bg-[#111111] border border-gray-800">
              <div className="w-12 h-12 mb-4 bg-gradient-to-br from-orange-400 to-orange-600 rounded-lg flex items-center justify-center">
                <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Revolutionized Legal Search</h3>
              <p className="text-gray-400">
                Search for vetted, honest, and effective attorneys with options to compare, insuring to multiple with ease, and more.
              </p>
            </div>

            <div className="p-8 rounded-lg bg-[#111111] border border-gray-800">
              <div className="w-12 h-12 mb-4 bg-gradient-to-br from-[#8A7FE4] to-purple-600 rounded-lg flex items-center justify-center">
                <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Class Action Inquiries</h3>
              <p className="text-gray-400">
                Have an issue? Your not alone. Talk about your problem and join with others experiencing the same issue to file a class action.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black py-16 border-t border-gray-800">
        <div className="max-w-6xl mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-12">
            <div>
              <h3 className="font-bold mb-4">Company</h3>
              <ul className="space-y-2">
                <li><button className="text-gray-400 hover:text-white">About us</button></li>
                <li><button className="text-gray-400 hover:text-white">Team</button></li>
                <li><button className="text-gray-400 hover:text-white">Careers</button></li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-4">Services</h3>
              <ul className="space-y-2">
                <li><button className="text-gray-400 hover:text-white">Branding</button></li>
                <li><button className="text-gray-400 hover:text-white">Web development</button></li>
                <li><button className="text-gray-400 hover:text-white">Digital marketing</button></li>
                <li><button className="text-gray-400 hover:text-white">Mobile app</button></li>
                <li><button className="text-gray-400 hover:text-white">SEO</button></li>
                <li><button className="text-gray-400 hover:text-white">User testing</button></li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-4">Resources</h3>
              <ul className="space-y-2">
                <li><button className="text-gray-400 hover:text-white">Blog</button></li>
                <li><button className="text-gray-400 hover:text-white">Case study</button></li>
                <li><button className="text-gray-400 hover:text-white">Testimonials</button></li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-4">Follow us</h3>
              <ul className="space-y-2">
                <li><button className="text-gray-400 hover:text-white">LinkedIn</button></li>
              </ul>
            </div>

            <div>
              <div className="flex items-center mb-4">
                <div className="w-8 h-8 bg-gradient-to-br from-orange-400 to-orange-600 rounded-lg mr-2" />
                <span className="font-bold">Lawdie</span>
              </div>
              <p className="text-sm text-gray-400 mb-4">Get latest updates</p>
              <input
                type="email"
                placeholder="Your email"
                className="w-full px-4 py-2 rounded bg-[#111111] border border-gray-800 focus:outline-none focus:border-[#8A7FE4]"
              />
            </div>
          </div>

          <div className="mt-16 text-center text-gray-400 text-sm">
            Created by Lawdie Inc.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;